<template>
  <Defaultlayout :userprofile="userprofile">
    <section class="order-main">
      <div class="container">
        <div class="order-box" v-if="getcartdata != null && getcartdata.status == '1'">
          <div class="profile-id">
            <div class="media">
              <div class="ordered-profile mr-3">
                <router-link :to="'/shopdetail?storeid='+getcartdata.data.store.store_id">
                  <img
                    :src="getcartdata.data.store.store_logo"
                    alt="..."
                    v-if="getcartdata.data.store.store_logo!=''"
                  >
                  <avatar
                    :fullname="getcartdata.data.store.store_name.split(' ')[0]"
                    :size="80"
                    :style="'border-radius:10px !important;'"
                    v-if="getcartdata.data.store.store_logo==''"
                  ></avatar>
                </router-link>
              </div>
              <div class="media-body">
                <div class="row form-row">
                  <div class="col-sm-7 order-description">
                    <router-link :to="'/shopdetail?storeid='+getcartdata.data.store.store_id" style="color:#000;">
                      <h5>{{getcartdata.data.store.store_name}}</h5>
                    </router-link>
                    <a class="location" href="#" v-if="getcartdata.data.store.store_address">
                      <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">
                      <span v-if="getcartdata.data.store.city_name"> {{getcartdata.data.store.city_name}}</span>
                      <span v-if="getcartdata.data.store.state_name">, {{getcartdata.data.store.state_name}}</span>
                    </a>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0)" @click="goBack">
                  <img src="../../assets/images/pre-slider-btn.png">
                </a>
            </div>
          </div>
        </div>
        <div class="order-place" v-if="getcartdata.status == '1'  && loading==false">
          <div class="d-flex justify-content-between flex-wrap">
            <h2 class="section-heading d-inline-block">Place order</h2>
            <div class="your-basket-right d-flex justify-content-between" v-if="delivery_pickup">
              <h5>{{getDeliveryType(delivery_pickup)}}</h5>
            </div>
          </div>
          <div class="row" style="padding-top: 10px;" v-if="getcartdata != undefined && getcartdata != null">
            <div class="form-group row clearfix">
              <label for="priscription" class="col-sm-2 control-label"></label>
              <div class="col-md-8">
                <table class="table" id="tblItemDetails" style="font-size: 14px !important;" v-if="custordrdelvryplace.order_prescription_images.length > 0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="gradeX" v-for="(file,index) in custordrdelvryplace.order_prescription_images" v-bind:key="index">
                      <td class="">
                        {{ file.name }}
                      </td>
                      <td>
                        <span style=" display: block; text-align: center; margin: 0 auto;">
                          <i @click="removeImage(index)">X</i>
                        </span>
                      </td>
                    </tr>  
                  </tbody>
                  </table>
                  <input class="btn m-b file" style="display:none;" type="file" id="LogoInput" @change="fileNameChanged($event)" accept='image/x-png,image/gif,image/jpeg' />
                </div>
              </div>
            </div>
          <div class="row" v-if="delivery_pickup != 1">
            <div class="col-lg-12 mb-4">
              <h6 v-if="iseatry == 1"><b>Take Away From:  </b> 
                <a class="location text-black" :href="store_map_link" target="_blank">
                  <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png"> {{store_address}}
                </a>
              </h6>
              <h6 v-else><b>Pickup From:  </b> 
                <a class="location text-black" :href="store_map_link" target="_blank">
                  <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png"> {{store_address}}
                </a>
              </h6>
            </div>
          </div>
          <div class="row">
                        <div class="col-lg-8" v-if="getcartdata.status == '1'">
                                 <div id="accordion" role="tablist" aria-multiselectable="true">
                <!-- Accordion Item 1 -->
                <div class="card border-0 rounded-0">
                  <div
                    class="card-header border-0 rounded-0"
                    role="tab"
                    id="accordionHeadingOne"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    data-target="#accordionBodyOne"
                    aria-expanded="false"
                    aria-controls="accordionBodyOne"
                  >
                    <div class="mb-0 row">
                      <div class="col-12 no-padding accordion-head">
                        <div
                          class="collapsed d-flex flex-wrap align-items-center justify-content-between w-100 px-0"
                        >
                          <h4 class="collapse-heading">My Cart Items</h4>
                          <div class="d-flex align-items-center">
                            <img
                              src="../../assets/images/downarrow-dark.png"
                              class="collapse-down-arrow"
                              alt="downarrow-dark"
                              role="button"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="accordionBodyOne"
                    class="collapse"
                    role="tabpanel"
                    aria-labelledby="accordionHeadingOne"
                    aria-expanded="false"
                    data-parent="accordion"
                  >
                    <div class="card-block col-12">
              <div class="table-responsive basket-list">
                <table class="table table-borderless" border="0">
                  <thead>
                    <tr>
                      <th class="order-item-main">Order Items</th>
                      <th class="unit-price-main">Unit Price</th>
                      <th class="quantity-main">Quantity</th>
                      <th class="subtotal-main">Subtotal</th>
                      <th class="close-main"></th>
                    </tr>
                  </thead>
                  <tbody v-for="(cartdata, index) in getcartdata.data.items" v-bind:key="index">
                    <tr class="first-item" v-for="(product, ind) in getAddedCartItems(cartdata.item_variants)" v-bind:key="ind">
                      <td class="order-item">
                        <div class="d-flex">
                          <div class="ordered-profile mr-3">
                            <router-link :to="'/productdetail?itemid=' + cartdata.item_id">
                              <img :src="cartdata.item_image" alt="apple">
                            </router-link>
                          </div>
                          <div class="order-description d-flex flex-wrap align-items-center">
                            <h5 class="w-100 mb-2">
                              <router-link :to="'/productdetail?itemid=' + cartdata.item_id" class="text-black">
                                {{ cartdata.item_name }}
                              </router-link>
                            </h5>
                            <h6 class="w-100 mb-2 text-black">
                              <span v-if="parseFloat(product.unit_quantity) != '0'">
                                {{ Number( product.unit_quantity ).toFixed(1) }}
                              </span>
                              <span v-if="product.unit_name != null">
                                {{ product.unit_name }}
                              </span>
                              <span v-if="product.packaging_name != null">
                                {{ product.packaging_name }}
                              </span>

                            </h6>
                            <h6 class="w-100 mb-2 text-black" v-if="parseFloat(product.min_qty) > 0 && parseFloat(product.max_qty) > 0">
                              (Min {{ product.min_qty }} - Max {{ product.max_qty }})
                            </h6>
                            <h6 class="w-100 mb-2 text-black" v-if="parseFloat(product.min_qty) > 0 && parseFloat(product.max_qty) == 0">
                            (Min {{ product.min_qty }})
                          </h6>
                          <h6 class="w-100 mb-2 text-black" v-if="parseFloat(product.min_qty) == 0 && parseFloat(product.max_qty) > 0">
                            (Max {{ product.max_qty }})
                          </h6>
                            <h6 class="w-100 mb-2 text-black" v-if="product.choices != null">
                              <span v-for="(choice,ind) in product.choices" v-bind:key="ind">
                                {{choice.variant_choice_name}}:
                                <span v-for="(choice_price,ind1) in choice.choice_prices" v-bind:key="ind1">
                                  <span v-if="(choice_price.is_added == 1)">
                                    {{choice_price.variant_sub_choice_name}}<template v-if="ind1 + 1 < choice.choice_prices.length">, </template>
                                  </span>
                                </span>
                                <br>
                              </span>
                            </h6>
                            <h6 class="w-100 mb-2 text-black" v-if="product.addons != null">
                              <span v-for="(addon,ind) in product.addons" v-bind:key="ind">
                                {{addon.variant_addon_name}}:
                                <span v-for="(addon_price,ind1) in addon.addon_prices" v-bind:key="ind1">
                                  <span v-if="(addon_price.is_added == 1)">
                                    {{addon_price.variant_sub_addon_name}}<template v-if="ind1 + 1 < addon.addon_prices.length">, </template>
                                  </span>
                                </span>
                                <br>
                              </span>
                            </h6>
                            <h6 class="w-100 mb-2 text-black" v-if="cartdata.combo_variants != null">
                              <span v-for="(combo,ind) in cartdata.combo_variants" v-bind:key="ind">
                                {{combo.combo_variant_name}}
                                <br>
                                <span class="ml-4" v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                                  <span v-if="parseFloat(combo.unit_quantity) != '0'">
                                    {{ Number( combo.unit_quantity ).toFixed(1) }}
                                  </span>
                                  <span v-if="combo.unit_name != null">
                                    {{ combo.unit_name }}
                                  </span>
                                  <span v-if="combo.packaging_name != null">
                                    {{ combo.packaging_name }}
                                  </span>
                                  <br>
                                </span>
                                <h6 class="w-100 mb-2 text-black" v-if="combo.choices != null">
                                  <span v-for="(choice,ind) in filterComboChoices(combo.choices)" v-bind:key="ind">
                                    <span v-if="(ind == 0)">{{choice.variant_choice_name}}: </span>
                                    {{choice.variant_sub_choice_name}}<template v-if="ind + 1 < filterComboChoices(combo.choices).length">, </template> 
                                  </span>
                                </h6>
                                <h6 class="w-100 mb-2 text-black" v-if="combo.addons != null">
                                  <span v-for="(addon,ind) in filterComboAddons(combo.addons)" v-bind:key="ind">
                                    <span v-if="(ind == 0)">{{addon.variant_addon_name}}: </span>
                                    {{addon.variant_sub_addon_name}}<template v-if="ind + 1 < filterComboAddons(combo.addons).length">, </template> 
                                  </span>
                                </h6>
                              </span>
                              <br>
                            </h6>
                            <span v-if="cartdata.prescription == 1" class="prescription-required">
                                Prescription required
                            </span>
                          </div>
                          <div class="basket-right">
                            <h5 class="price text-center padding-adjustment">
                              <span v-html="priceFormatter(getUnitPrice(product, cartdata.combo_variants))"></span>
                            </h5>
                            <div class="d-flex justify-content-between padding-adjustment">
                              <div class="number row mx-0 ">
                                 {{product.quantity}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="unit-price valign-middle">
                        <span>
                          <h5 class="price padding-adjustment">
                            <span v-html="priceFormatter(getUnitPrice(product, cartdata.combo_variants))"></span>
                          </h5>
                        </span>
                      </td>
                      <td class="valign-middle quantity-main ">
                        <span>
                          <div class="d-flex justify-content-between padding-adjustment">
                            <div class="number row mx-0">
                               {{product.quantity}}
                            </div>
                          </div>
                        </span>
                      </td>
                      <td class="valign-middle price-main">
                        <h5 class="price">
                          <span class="padding-adjustment" v-html="priceFormatter(calPriceByQty(getUnitPrice(product, cartdata.combo_variants), product.quantity))"></span>
                        </h5>
                      </td>
                      <td class="valign-middle close-main d-none d-md-table-cell">
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
                    </div>
                  </div>
                </div>
                </div>       

              <div id="accordion" role="tablist" aria-multiselectable="true">
                <!-- Accordion Item 1 -->
                <div class="card border-0 rounded-0" v-if="delivery_pickup == 1">
                  <div
                    class="card-header border-0 rounded-0"
                    role="tab"
                    id="accordionHeadingTwo"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    data-target="#accordionBodyTwo"
                    aria-expanded="false"
                    aria-controls="accordionBodyTwo"
                  >
                    <div class="mb-0 row">
                      <div class="col-12 no-padding accordion-head">
                        <div
                          class="collapsed d-flex flex-wrap align-items-center justify-content-between w-100 px-0"
                        >
                          <h4 class="collapse-heading">Delivery Address</h4>
                          <div class="d-flex align-items-center">
                            <img
                              src="../../assets/images/downarrow-dark.png"
                              class="collapse-down-arrow"
                              alt="downarrow-dark"
                              role="button"
                            >
                          </div>
                          <span style="color: red;"> {{ delivery_address_note  }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="accordionBodyTwo"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="accordionHeadingTwo"
                    aria-expanded="false"
                    data-parent="accordion"
                  >
                    <div class="card-block col-12">
                      <div class="address-book" v-if="address_loading==false">
                        <div class="row">
                          <div
                            :class="
                              address.is_default == '1'
                                ? 'col-lg-3 col-md-4 address-book-wrap active'
                                : 'col-lg-3 col-md-4 address-book-wrap'
                            "
                            v-for="(address,index) in getaddressdetails.data"
                            v-bind:key="index"
                            v-show="getaddressdetails != '' && getaddressdetails.data != '' && index <= 0"
                          >
                            <h3 class="delivery mb-0 w-100">Deliver to</h3>
                            <div
                              class="address"
                              @click="setDeliveryAddress(address.customer_address_id, address)"
                              style="cursor: pointer;"
                            >
                              <div class="address-one w-100">
                                <h5>{{ address.alias }}<span class="incomplete-address">{{address.address2 != '' ? '' : '(Incomplete Address)'}}</span></h5>
                                <!-- <h6>{{ address.address }}</h6> -->
                                <p>
                                  <span v-if="address.address2">{{ address.address2 }}, </span>
                                  <span v-if="address.address">{{ address.address }}<br></span>
                                  <span v-if="address.area_name">{{ address.area_name }},<br></span>
                                  <span v-if="address.city_name">{{ address.city_name }},<br></span>
                                  <span v-if="address.state_name">{{ address.state_name }} {{ address.zipcode }}</span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 address-book-wrap">
                            <h3 class="delivery mb-0 w-100">Deliver to</h3>
                            <div class="address d-flex justify-content-center align-items-center flex-wrap">
                              <div class="add-button">
                                <button
                                  type="button"
                                  class="d-block mx-auto add-btn"
                                  data-toggle="modal"
                                  @click="changeDeliveryAddress()"
                                >
                                  <span class="d-block">
                                    <img src="../../assets/images/svgs/plus.svg" alt="plus" class="d-block">
                                  </span>
                                </button>
                                <h6 class="mt-3">Change Address</h6>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="container" v-if="address_loading==true">
                        <p class="text-center">
                          <img src="../../assets/images/loading.gif">
                          <br/>
                         Do not press the back button or exit the browser.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Accordion Item 3 -->
                <div class="card border-0 rounded-0" v-if="delivery_pickup == 1">
                  <div class="card-header border-0 rounded-0"
                   data-toggle="collapse"
                   data-parent="#accordion"
                   data-target="#accordionBodyThree"
                   aria-expanded="false"
                   aria-controls="accordionBodyThree"
                   role="tab" id="accordionHeadingThree">
                    <div class="mb-0 row">
                      <div class="col-12 no-padding accordion-head">
                        <div class="collapsed d-flex flex-wrap align-items-center justify-content-between w-100 px-0">
                          <h4 class="collapse-heading">Delivery Method</h4>
                          <div class="d-flex align-items-center">
                            <img
                              src="../../assets/images/downarrow-dark.png"
                              class="collapse-down-arrow"
                              alt="downarrow-dark"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="accordionBodyThree"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="accordionHeadingThree"
                    aria-expanded="false"
                    data-parent="accordion"
                  >
                    <div class="card-block col-12">
                      <form class="delivery-method">
                        <div class="d-flex align-items-center form-group">
                          <div class="radio-container d-flex w-100">
                            <div class="radio-dt">
                              <input
                                type="radio"
                                name="deliver"
                                id="deliver-now"
                                v-model="delivery_now_later"
                                value="1"
                              >
                              <label for="deliver-now">
                                <span class="d-inline-block" v-if="getcartdata.data.order_settings.delivery_time_type == 0">Delivery Time</span>
                                <span class="d-inline-block" v-else>
                                  <h5 style=" margin-right: 5px;">
                                    Delivery Slot
                                  </h5>
                              </span>                          
                              </label>
                              <div v-for="(address,index) in getaddressdetails.data"  v-bind:key="index">
                                    <span v-if="address.is_default == '1'" style="font-size: 14px;"> 
                                      (We deliver for your zip code {{address.zipcode1}} on {{ zoneFormat()}})
                                    </span>
                                  </div>
                              </div>
                            <!-- <div class="radio-dt" v-if="getcartdata.data.order_settings.allow_scheduling == 1">
                              <input
                                type="radio"
                                name="deliver"
                                id="deliver-later"
                                v-model="delivery_now_later"
                                value="2"
                              >
                              <label for="deliver-later">
                                <span class="d-inline-block">Schedule your Delivery</span>
                              </label>
                            </div> -->
                          </div>
                        </div>
                        <h6 class="mb-0 mt-4" v-if="delivery_now_later == '1'">
                          <span
                          class="d-block mt-1 label"
                          v-if="getcartdata.data.order_settings.delivery_time_type == 0 && (getcartdata.data.order_settings.from_min != null || getcartdata.data.order_settings.from_min != 0) && (getcartdata.data.order_settings.to_min != null || getcartdata.data.order_settings.to_min != 0)"
                          >
                          <span>Your order will be delivered in <span v-if="getcartdata.data.order_settings.from_min != '0'">{{ getcartdata.data.order_settings.from_min }}</span><span v-if="getcartdata.data.order_settings.to_min != '0'"> - {{ getcartdata.data.order_settings.to_min }}</span></span>
                          <span
                            v-if="getcartdata.data.order_settings.delivery_duration!=null"
                          > 
                          {{ getcartdata.data.order_settings.delivery_duration==1?"min(s)": getcartdata.data.order_settings.delivery_duration==2?"hr(s)":"day(s)"}}
                          </span>
                          </span>
                        </h6>
                        <div class="mt-4" v-if="delivery_now_later == '1' && getcartdata.data.order_settings.delivery_slots != null && getcartdata.data.order_settings.delivery_time_type == 1">
                          <select
                            class="form-control col-sm-6 col-12"
                            v-if="delivery_now_later == '1'"
                            v-model="selected_slot"
                          >
                            <option value="">Select Delivery Slot</option>
                            <option
                              v-for="(delivery_slot,index) in getcartdata.data.order_settings.delivery_slots"
                              v-bind:key="index"
                              :value="index"
                            >
                              {{delivery_slot.slot}}
                            </option>
                          </select>
                        </div>

                        <div class="date-timepicker-main d-flex flex-wrap" v-if="delivery_now_later == '2'">
                          <div class="form-group date-timepicker mt-3 mr-4">
                            <date-picker type="date" v-model="scheduled_date" :placeholder="datePickerFormat" :format="datePickerFormat" :default-value="todayDate" :disabled-date="disabledBeforeToday" valueType="format"></date-picker>
                            <label for="date"></label>
                          </div>
                          <div class="form-group date-timepicker ">
                            <input type="time" name="scheduled_time" id="scheduled_time" class="mt-3" v-model="scheduled_time">
                            <label for="time"></label>
                          </div>
                        </div>
                        <h6 class="mb-0" v-if="delivery_now_later == '2'">
                          <span class="mt-1 label" v-if="getcartdata.data.schedule_delivery_date != null && !getcartdata.data.schedule_delivery_date.includes('00:00:00')">
                            Not before {{ getcartdata.data.schedule_delivery_date }}
                          </span>
                          <a href="javascript:void(0)" @click="getStoreBusinessHours()" :style="textcolor">Business Hours</a>
                        </h6>

                      </form>
                    </div>
                  </div>
                  
                </div>

                <!-- Accordion Item 4 -->
                <div class="card border-0 rounded-0" v-if="delivery_pickup == 2">
                  <div class="card-header border-0 rounded-0" role="tab" id="accordionHeadingFour"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  data-target="#accordionBodyFour"
                  aria-expanded="false"
                  aria-controls="accordionBodyFour"
                  >
                    <div class="mb-0 row">
                      <div class="col-12 no-padding accordion-head">
                        <div class="collapsed d-flex flex-wrap align-items-center justify-content-between w-100 px-0">
                          <h4 class="collapse-heading" v-if="iseatry == 1">Take Away Method</h4>
                          <h4 class="collapse-heading" v-else>Pickup Method</h4>
                          <div class="d-flex align-items-center">
                            <img
                              src="../../assets/images/downarrow-dark.png"
                              class="collapse-down-arrow"
                              alt="downarrow-dark"
                              role="button"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="accordionBodyFour"
                    class="collapse show"
                    role="tabpanel"
                    aria-labelledby="accordionHeadingFour"
                    aria-expanded="false"
                    data-parent="accordion"
                  >
                    <div class="card-block col-12">
                      <form class="delivery-method">
                        <div class="d-flex align-items-center form-group">
                          <div class="radio-container d-flex w-100">
                            <div class="radio-dt">
                              <input
                                type="radio"
                                name="deliver"
                                id="deliver-now"
                                v-model="delivery_now_later"
                                value="1"
                              >
                              <label for="deliver-now" v-if="getcartdata.data.order_settings.pickup_time_type == 0">
                                <span class="d-inline-block" v-if="iseatry == 1">Take Away Time</span>
                                <span class="d-inline-block" v-else>Pickup Time</span>
                              </label>
                              <label for="deliver-now" v-else>
                                <span class="d-inline-block" v-if="iseatry == 1">Take Away Slot</span>
                                <span class="d-inline-block" v-else>Pickup Slot</span>
                              </label>                              
                            </div>
                            <div class="radio-dt" v-if="getcartdata.data.order_settings.pickup_scheduling == 1">
                              <input
                                type="radio"
                                name="deliver"
                                id="deliver-later"
                                v-model="delivery_now_later"
                                value="2"
                              >
                              <label for="deliver-later">
                                <span class="d-inline-block" v-if="iseatry == 1">Schedule your Take Away</span>
                                <span class="d-inline-block" v-else>Schedule your Pickup</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <h6 class="mb-0 mt-4" v-if="delivery_now_later == '1'">
                          <span
                          class="d-block mt-1 label"
                          v-if="getcartdata.data.order_settings.pickup_time_type == 0 && (getcartdata.data.order_settings.from_min != null || getcartdata.data.order_settings.from_min != 0) && (getcartdata.data.order_settings.to_min != null || getcartdata.data.order_settings.to_min != 0)"
                          >
                          <span v-if="iseatry == 1">Your order will be ready for take away in <span v-if="getcartdata.data.order_settings.from_min != '0'">{{ getcartdata.data.order_settings.pickup_from }}</span><span v-if="getcartdata.data.order_settings.pickup_to != '0'"> - {{ getcartdata.data.order_settings.pickup_to }}</span></span>
                          <span v-else>Your order will be ready for pickup in <span v-if="getcartdata.data.order_settings.from_min != '0'">{{ getcartdata.data.order_settings.pickup_from }}</span><span v-if="getcartdata.data.order_settings.pickup_to != '0'"> - {{ getcartdata.data.order_settings.pickup_to }}</span></span>
                          <span
                            v-if="getcartdata.data.order_settings.pickup_duration!=null"
                          > 
                          {{ getcartdata.data.order_settings.pickup_duration==1?"min(s)": getcartdata.data.order_settings.pickup_duration==2?"hr(s)":"day(s)"}}
                          </span>
                          </span>
                        </h6>
                        <div class="mt-4" v-if="delivery_now_later == '1' && getcartdata.data.order_settings.pickup_slots != null && getcartdata.data.order_settings.pickup_time_type == 1">
                          <select
                            class="form-control col-6"
                            v-if="delivery_now_later == '1'"
                            v-model="selected_pickup_slot"
                          >
                            <option v-if="iseatry == 1" value="">Select Take Away Slot</option>
                            <option v-if="iseatry != 1" value="">Select Pickup Slot</option>
                            <option
                              v-for="(pickup_slot,index) in getcartdata.data.order_settings.pickup_slots"
                              v-bind:key="index"
                              :value="index"
                            >
                            {{pickup_slot.slot}}
                            </option>
                          </select>
                        </div>

                        <div class="date-timepicker-main d-flex flex-wrap" v-if="delivery_now_later == '2'">
                          <div class="form-group date-timepicker mt-3 mr-4">
                            <date-picker type="date" v-model="scheduled_pickup_date" :placeholder="datePickerFormat" :format="datePickerFormat" :default-value="todayDate" :disabled-date="disabledBeforeToday" valueType="format"></date-picker>
                            <label for="date"></label>
                          </div>
                          <div class="form-group date-timepicker ">
                            <input type="time" name="scheduled_pickup_time" id="scheduled_pickup_time" class="mt-3" v-model="scheduled_pickup_time">
                            <label for="time"></label>
                          </div>
                        </div>
                        <h6 class="mb-0" v-if="delivery_now_later == '2'">
                          <span class="mt-1 label" v-if="getcartdata.data.schedule_pickup_date != null && !getcartdata.data.schedule_pickup_date.includes('00:00:00')">
                            Not before {{ getcartdata.data.schedule_pickup_date }}
                          </span>
                          <a href="javascript:void(0)" @click="getStoreBusinessHours()" :style="textcolor">Business Hours</a>
                        </h6>

                      </form>
                    </div>
                  </div>
                  
                </div>

                <!-- Accordion Item 3 -->
                <div class="card border-0 rounded-0">
                  <div class="card-header border-0 rounded-0" role="tab" id="accordionHeadingFive">
                    <div class="mb-0 row">
                      <div class="col-12 no-padding accordion-head">
                        <div class="collapsed d-flex flex-wrap align-items-center justify-content-between w-100 px-0">
                          <h4 class="collapse-heading">Remark</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="accordionBodyFive" class="collapse show remark">
                    <div class="card-block">
                      <div class="your-remark mx-3">
                        
                        <textarea v-if="iseatry == 1" cols="50" rows="3" placeholder="Please let us know if you have any inputs, cooking instructions for us to follow while processing the Order" v-model="remarks"></textarea>
                        <textarea v-else cols="50" rows="3" placeholder="Please let us know if you have any inputs for us to follow while processing your Order" v-model="remarks"></textarea>
                      </div>
                      <div class="attachment d-flex" @click="uploadimage()" v-if="getcartdata.data.items.filter(x=>x.prescription == 1).length > 0">
                        <div  class="attachment-icon d-flex justify-content-center align-items-center rounded-circle mr-2">
                          <div class="custom-file-input d-flex justify-content-center align-items-center"> 
                            <label for="document_name" class="file-icon-wrap">
                            <img src="../../assets/images/svgs/attachment-icn.svg" alt="attachment-icon"></label>
                          </div>
                        </div>
                        <div class="d-flex flex-wrap attachment-description">
                          <h5 class="mb-0 w-100">Upload Prescription</h5>
                          <span class=" w-100">Supported formats (jpg,jpeg and png. Size upto 5MB)</span>
                        </div>
                      </div>
                  <table class="table" id="tblItemDetails" style="font-size: 14px !important;margin-top:10px;" v-if="custordrdelvryplace.order_prescription_images.length > 0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="gradeX" v-for="(file,index) in custordrdelvryplace.order_prescription_images" v-bind:key="index">
                      <td class="">
                        {{ file.name }}
                      </td>
                      <td>
                        <span title="Delete" style="display: block; text-align: center; margin: 0 auto;">
                          <i @click="removeImage(index)">X</i>
                        </span>
                      </td>
                    </tr>  
                  </tbody>
                  </table>
                      <div class="card border-0 rounded-0" v-show="show_billing_address == 1">
                        <div
                          class="card-header border-0 rounded-0"
                          role="tab"
                          id="accordionHeadingSix"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          data-target="#accordionBodySix"
                          aria-expanded="false"
                          aria-controls="accordionBodySix"
                        >
                          <div class="mb-0 row">
                            <div class="col-12 no-padding accordion-head">
                              <div
                                class="collapsed d-flex flex-wrap align-items-center justify-content-between w-100 px-0"
                              >
                                <h4 class="collapse-heading">Billing Address</h4>
                                <div class="d-flex align-items-center">
                                  <img
                                    src="../../assets/images/downarrow-dark.png"
                                    class="collapse-down-arrow"
                                    alt="downarrow-dark"
                                    role="button"
                                  >
                                </div>
                                <span style="color: red;"> {{ billing_address_note  }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="accordionBodySix"
                          class="collapse show"
                          role="tabpanel"
                          aria-labelledby="accordionHeadingSix"
                          aria-expanded="false"
                          data-parent="accordion"
                        >
                          <div class="card-block col-12">

                            <div class="address-book" v-if="address_loading==false">
                              <div class="row">
                                <div
                                  :class="
                                    address.customer_address_id == billing_address_id
                                      ? 'col-lg-3 col-md-4 address-book-wrap active'
                                      : 'col-lg-3 col-md-4 address-book-wrap'
                                  "
                                  :v-if="address.customer_address_id == billing_address_id"
                                  v-for="(address,index) in getbillingaddressdetails.data"
                                  v-bind:key="index"
                                  v-show="getbillingaddressdetails != '' && getbillingaddressdetails.data != '' &&  index <= 0"
                                >
                                  <h3 class="delivery mb-0 w-100">Billed to</h3>
                                  <div
                                    class="address"
                                    @click="setBillingAddress(address.customer_address_id)"
                                    style="cursor: pointer;"
                                  >
                                    <div class="address-one w-100">
                                      <h5>{{ address.alias }}<span class="incomplete-address">{{address.address2 != "" ? '' : '(Incomplete Address)'}}</span></h5>
                                      <!-- <h6>{{ address.address }}</h6> -->
                                      <p>
                                        <span v-if="address.address2">{{ address.address2 }}, </span>
                                        <span v-if="address.address">{{ address.address }},<br></span>
                                        <span v-if="address.area_name">{{ address.area_name }},<br></span>
                                        <span v-if="address.city_name">{{ address.city_name }},<br></span>
                                        <span v-if="address.state_name">{{ address.state_name }} {{ address.zipcode }}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                

                                <div class="col-lg-3 col-md-4 address-book-wrap">
                                  <h3 class="delivery mb-0 w-100">Billing to</h3>
                                  <div class="address d-flex justify-content-center align-items-center flex-wrap">
                                    <div class="add-button">
                                      <button
                                        type="button"
                                        class="d-block mx-auto add-btn"
                                        @click="changeBillingAddress()"
                                      >
                                        <span class="d-block">
                                          <img src="../../assets/images/svgs/plus.svg" alt="plus" class="d-block">
                                        </span>
                                      </button>
                                      <h6 class="mt-3">Change Address</h6>
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>
                            <div class="container" v-if="address_loading==true">
                              <p class="text-center">
                                <img src="../../assets/images/loading.gif">
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <label>Payment Method</label><br/>
                      <input class="btn m-b file" style="display:none;" type="file" id="LogoInput" @change="fileNameChanged($event)" accept='image/x-png,image/gif,image/jpeg' />
                      <span v-if="getcartdata.data.is_wallet == 1 && (modules.is_wallet == 1) && (getcartdata.data.wallet_balance != 0.00) && wallet_max_amount != 'null'">
                        <input type="checkbox" v-model="is_wallet_applied" /> Apply Wallet (Current Balance: {{parseFloat(is_wallet_applied ? getcartdata.data.wallet_balance-  (Number(grandTotal) > Number(getcartdata.data.wallet_balance)?(Number(getcartdata.data.wallet_balance) > Number(wallet_max_amount) ? wallet_max_amount : getcartdata.data.wallet_balance):(Number(grandTotal) > Number(wallet_max_amount) ? wallet_max_amount : grandTotal)):getcartdata.data.wallet_balance).toFixed(2)}}, Max applicable: {{wallet_max_amount}})
                      </span>
                      <span v-if="getcartdata.data.is_wallet == 1 && (modules.is_wallet == 1) && (getcartdata.data.wallet_balance != 0.00) && wallet_max_amount == 'null'">
                        <input type="checkbox" v-model="is_wallet_applied" /> Apply Wallet (Current Balance: {{parseFloat(is_wallet_applied ? getcartdata.data.wallet_balance-  (Number(grandTotal) > Number(getcartdata.data.wallet_balance)?(Number(getcartdata.data.wallet_balance) > Number(wallet_max_amount) ? wallet_max_amount : getcartdata.data.wallet_balance):(Number(grandTotal) > Number(wallet_max_amount) ? wallet_max_amount : grandTotal)):getcartdata.data.wallet_balance).toFixed(2)}})
                      </span>
                      <span :class="(grandTotal-(Number(grandTotal) > Number(getcartdata.data.wallet_balance)?(getcartdata.data.wallet_balance):(Number(grandTotal) > Number(wallet_max_amount) ? wallet_max_amount : grandTotal))) <= 0 && is_wallet_applied == true ? 'hidepaymentmethod':'showpaymentmethod'">
                      <select v-if="paymentMethods.length == 1" class="form-control col-12 col-md-6 mb-2" v-model="paymentMethods[0].payment_method_id">
                        <option :value="paymentMethods[0].payment_method_id">{{ paymentMethods[0].method_name_display }}</option>
                      </select>
                      
                      <select v-else class="form-control col-12 col-md-6 mb-2" v-model="payment_method">
                        {{payment_method}}
                        <option value="">Select Payment Method</option>
                        
                        <option
                          v-for="(payment_method,index) in paymentMethods"
                          v-bind:key="index"
                          :value="payment_method.payment_method_id"
                          >
                          {{payment_method.method_name_display}}
                          </option>
                        </select>
                        </span>
                        <br/>
                        <span class="text-danger" v-if="getMaxOrderValue()">Max order allowed of {{currency_symbol}}{{getMaxOrderValue()}}</span>
                        <div class="pay-online-card" v-if="payment_method == 4 && save_cards_status == 1">
                          <p>Pay securely using your credit card.</p>

                          <div>
                            <label for="credit-card-number">Existing Card</label>
                            <select :style="add_newcard ? 'background-color: #5e5e5e !important; color:#fff' : ''" :disabled="add_newcard" id="credit-card-number" v-if="save_cards_data.length > 0" class="form-control col-12 col-md-6 mb-2" v-model="customer_card_id" @change="changeCard($event)">
                              <option value=0>Select Existing Card</option>
                              <option 
                              v-for="(card,index) in save_cards_data"
                              :key="index"
                              :value="card.customer_card_id">{{card.card_type}} - {{ card.card_number }}</option>
                            </select>
                            <input type="button" v-if="customer_card_id!=0" @click="yesClicked()" value="Delete Card" class="delete-card" id="delete-card" name="delete-card">
                          </div>

                          <input
                            type="checkbox"
                            id="add-new-card"
                            v-model="add_newcard"
                            name="add-new-card"
                            @click="addNewCard()"
                          >
                          <label for="add-new-card">Add New Credit Card</label>
                          
                        </div>
                        <div class="pay-online pt-2" v-if="payment_method == 4 && (save_cards_status == 0 || add_newcard == true)">
                          <form class="payment-form" method="post" data-vv-scope="form2" @submit.prevent="validateBeforeSubmit">
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label for="ccname">Name on card</label>
                                <input 
                                  type="text" 
                                  class="form-control" 
                                  :class="{error: errors.has('form2.ccname')}" 
                                  id="ccname" 
                                  placeholder="Name on card" 
                                  name="ccname" 
                                  v-model="ccname" 
                                  v-validate="'required'">
                                <span v-show="errors.has('form2.ccname')" class="help is-danger">Name on card is required</span>
                              </div>
                              <div class="col-md-6 mb-3">
                                <label for="ccnumber">Card number</label>
                                <input 
                                  type="text" 
                                  class="form-control" 
                                  :class="{error: errors.has('form2.ccnumber')}" 
                                  id="ccnumber" 
                                  placeholder="Card number" 
                                  name="ccnumber" 
                                  v-model="ccnumber" 
                                  v-validate="'required'"
                                  maxlength="19"
                                  v-cardformat:formatCardNumber>
                                <span v-show="errors.has('form2.ccnumber')" class="help is-danger">Card number is required</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 mb-3">
                                <label for="ccexpiration">Expiration</label>
                                  <input 
                                    type="text" 
                                    class="form-control" 
                                    :class="{error: errors.has('form2.ccexpiration')}" 
                                    id="ccexpiration" 
                                    placeholder="MM / YYYY" 
                                    name="ccexpiration" 
                                    v-model="ccexpiration" 
                                    v-validate="'required'"
                                    v-cardformat:formatCardExpiry>
                                <span v-show="errors.has('form2.ccexpiration')" class="help is-danger">Expiration is required</span>
                              </div>
                              <div class="col-md-6 mb-3">
                                <label for="cccvv">CVV</label>
                                <input 
                                  type="text" 
                                  class="form-control col-md-3" 
                                  :class="{error: errors.has('form2.cccvv')}" 
                                  id="cccvv" 
                                  placeholder="CVV" 
                                  name="cccvv" 
                                  v-model="cccvv" 
                                  v-validate="'required'"
                                  v-cardformat:formatCardCVC>
                                  <span v-show="errors.has('form2.cccvv')" class="help is-danger">Security code is required</span>
                              </div>
                            </div>
                          </form>
                        </div>
                        <br/>
                        <div v-if="itemTotal >= parseFloat(amount_for_id_proof)">
                          <label>Upload ID Proof</label><br/>
                            <div>
                              <div>
                                  <input type="text" v-model="selectedIdProof" disabled />
                                  <button v-if="selectedIdProof" @click="removeIdProof">×</button>
                                  <button @click="openIdProof">Browse</button>
                                  <input type="file" ref="IdProofRef" style="display: none" accept=".pdf,.jpg,.jpeg,.png" @change="selectIdProof" />
                              </div>
                                <span class="help is-danger fileuplderr">Only upload pdf, jpg, jpeg or png file.</span>
                                <br/>
                              <span>{{id_proof_notes}}</span>
                              <div>
                                  <img :src="imagePreview" v-if="imagePreview" style="width: 200px; height: 200px" />
                              </div>
                          </div>
                        </div>
                        <br>
                        <div v-if="itemTotal >= parseFloat(amount_for_signature)">
                              <div style="text-align: end; width: 608px; padding-bottom: 10px;">
                                <div class="signature-design" style="display: flex;align-items: end; justify-content: space-between;">
                                    <h3>Signature</h3>
                                    <a href="javascript:void(0)" style="color: blue;" @click="clear">Clear</a>
                                </div>
                              </div>
                            <VueSignaturePad class="signature" width="608px" height="250px" ref="signaturePad"/>
                              <br/>  
                        </div>
                        <br>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-lg-4" v-if="getcartdata.status == '1'">
              <div class="order-detail">
                <h2 class="section-heading mt-3">Order Details</h2>
 <!-- && getOfferlistdata.status == '1' -->
                <div class="avilable-offer" v-if="getOfferlistdata != null">
                  <p data-toggle="modal" data-target="#available-offer" class="apply-offer">Apply Available Offer</p>
                  <div class="d-flex align-items-center justify-content-between apply-wrap">
                    <form @submit.prevent="validateOfferCode">
                      <input type="text" class="offer-input" name="appliedoffrcode" v-model="appliedoffrcode">
                      <span class="clear-btn mr-2" @click="removeOfferCode">×</span>         
                      <button class="btn apply rounded-0"> Apply</button> 
                    </form>
                  </div>
                  <span v-if="appliedoffrtitle != ''">{{ appliedoffrtitle }}</span><br/>
                  <span class="appliedofferremarks" v-if="appliedoffrremarks != ''">{{ appliedoffrremarks }}</span>
                </div>
                <ul class="payment-details-wrap list-unstyled mb-0" v-if="order_details_loading==false">
                  <li class="d-flex justify-content-between">
                    <h5 class="mb-0">Item Total</h5>
                    <p class="mb-0">
                      <span v-html="priceFormatter(itemTotal)"></span>
                    </p>
                  </li>
                  <li
                    class="d-flex justify-content-between delivery-charge"
                    v-if="delivery_charges != null && delivery_pickup == 1"
                  >
                    <h5 class="mb-0">Delivery Charges
                      <span class="d-block mt-1 label" v-if="delivery_charges.data.msg != null">
                        {{delivery_charges.data.msg}}
                      </span>
                    </h5>
                    <p class="mb-0">
                      <span v-html="priceFormatter(delivery_charges.data.delivery_charges)"></span>
                    </p>
                  </li>
                  
                  <li
                    class="d-flex justify-content-between delivery-charge"
                    v-if="this.getcartdata.total_tax  != '' && (parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost)).toFixed(2) != '0.00'"
                  >
                    <h5 class="mb-0">Taxes & Charges
                        <span class="tax-and-charges placeorder-section"><b><img src="../../assets/images/svgs/infoicon.svg"/></b>
                        <span class="title-span" v-html="(parseFloat(this.getcartdata.data.packaging_cost).toFixed(2) != 0.00 ?'Store Packaging Cost : '+this.currency_symbol+''+parseFloat(this.getcartdata.data.packaging_cost).toFixed(2)+ '<br/>':'')+(parseFloat(this.getcartdata.data.total_tax).toFixed(2) != 0.00 ?`${store_tax} : `+this.currency_symbol+''+parseFloat(this.getcartdata.data.total_tax).toFixed(2):'')">
                        </span>
                      </span>
                      </h5> 
                    <p class="mb-0">
                      <span v-html="priceFormatter((parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost)).toFixed(2))"></span>
                    </p>
                  </li>
                  <li class="discount d-flex justify-content-between" v-if="offer_discount">
                    <h5 class="mb-0">Offer ({{this.appliedoffrcode}})</h5>
                    <p class="mb-0">- <span v-html="priceFormatter(this.offer_discount)"></span></p>
                  </li>
                  <li class="total d-flex justify-content-between">
                    <h5>Total Amount</h5>
                    <p class="mb-0">
                      <span v-html="priceFormatter(grandTotal)"></span>
                    </p>
                  </li>
                  <li
                    :class="is_wallet_applied ? 'd-flex justify-content-between delivery-charge showwallet':'d-flex justify-content-between delivery-charge hidewallet'"
                  >
                    <h5 class="mb-0 walletAmount">Paid by Wallet
                    </h5>
                    <p class="mb-0 walletAmount">
                      -<span v-html="priceFormatter((Number(grandTotal) > Number(getcartdata.data.wallet_balance)?(Number(getcartdata.data.wallet_balance) > Number(wallet_max_amount) ? wallet_max_amount : getcartdata.data.wallet_balance):(Number(grandTotal) > Number(wallet_max_amount) ? wallet_max_amount : grandTotal)))"></span>
                    </p>
                  </li>
                  <li
                    :class="is_wallet_applied ? 'd-flex justify-content-between delivery-charge showwallet':'d-flex justify-content-between delivery-charge hidewallet'"
                  >
                    <h5 class="mb-0">Final Amount
                    </h5>
                    <p class="mb-0">
                      <span v-html="priceFormatter(Number(grandTotal)-(Number(grandTotal) > Number(getcartdata.data.wallet_balance)?(Number(getcartdata.data.wallet_balance) > Number(wallet_max_amount) ? wallet_max_amount : getcartdata.data.wallet_balance):(Number(grandTotal) > Number(wallet_max_amount) ? wallet_max_amount : grandTotal)))"></span>
                    </p>
                  </li>
                  
                  <span v-if="!btnPlaceOrder" :disabled='btnPlaceOrder' :class="btnPlaceOrder ? 'disablebtn' : ''">
                    <li class="button row mx-0 justify-content-center mb-0">
                      <a
                        :style="colorObj"
                        @click="verifyOrder"
                        
                        class="btn button-style check-out w-100 hoverEffect"
                      >Place Order</a>
                    </li>
                  </span>
                </ul>
                <div class="container" v-if="order_details_loading==true">
                  <p class="text-center">
                    <img src="../../assets/images/loading.gif" />
                    <br/>
                     Do not press the back button or exit the browser.
                  </p>
                </div>
              </div>
            </div>
                <div v-if="isDeletePopupVisible" class="delete-popup">
                    <div class="popup-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Alert</h5>
                      </div>
                        <div class="modal-body">Delete the saved card?</div>
                        <div class= "modal-footer">
                          <button type="button" class="btn btn-secondary" @click="noClicked()">No</button>&nbsp;
                          <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="deleteCard(customer_card_id)" >Yes</button>
                        </div>
                    </div>
                </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="loading==true">
        <p class="text-center">
          <img src="../../assets/images/loading.gif" />
          <br/>
          Do not press the back button or exit the browser.
        </p>
      </div>
    </section>
    <div v-if="getOfferlistdata != null ">
    
      <ApplyOffer :getofferdetails="getOfferlistdata" @update="applyOfferCode"></ApplyOffer>
      <OfferDetail></OfferDetail>
    </div>
    <div
      class="modal fade"
      id="BusinessHoursModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="BusinessHoursModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="BusinessHoursModalLabel">Store Business Hours</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h6>You can order from the store in the following Business Hours</h6>
            <div class="pt-4" v-if="store_business_hours != null">
                <div 
                  v-for="(business_hours, index) in store_business_hours.business_hours"
                  v-bind:key="index">
                  <h6>{{business_hours}}</h6>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" :style="colorObj" class="btn button-style hoverEffect" data-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
    
    <div 
      class="modal fade add-address-placeorder-popup" 
      id="address-pop-up" 
      tabindex="-1" 
      role="dialog" 
      aria-hidden="true"
      aria-labelledby="AddAddressModalLabel" 
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body">
            <h2 class="page-heading text-center">Add Address</h2>
            <div class="bottom-section">
                <div class="alert alert-success" role="alert" v-show="enablesavesucadrsmesg">
                  {{ getsaveaddressmsg}}
                </div>
                <div class="alert alert-danger" role="alert" v-show="enableerradrsmessage">
                  {{ geterraddrssmsg}}
                </div>
                 <form
                    class="address-form form-horizontal animated-form"
                    @submit.prevent="addAddress"
                    method="post"
                  >
                    <div class="form-row" v-show="default_address_visiblity">
                      <div class="form-group form-group-placeorder checkbox col-md-12">
                        <input
                          type="checkbox"
                          id="default_address"
                          v-model="default_address"
                          name="default_address"
                        >
                        <label for="default_address">Default Address</label>
                      </div>
                    </div>
                    <div class="form-row-gmap" v-if="use_google_map == 1">
                    <GmapMap
                    :center="{lat:latitude, lng:longitude}"
                    @click="center=m.position"
                    :zoom="zoomProperty"
                    map-type-id="roadmap"
                    ref="addAddressMap"
                    style="width:100%; height: 300px;margin-bottom: 30px;margin-left: auto;margin-right: auto;"
                    >
                    <GmapMarker
                    :key="index"
                    :v-if="markers.length > 0"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                     @dragend="updateCoordinates"
                    />
                    </GmapMap>
                    </div>
                    <div class="form-row form-row-autocomplete" v-if="use_google_map == 1">
                    <div class="form-group col-md-12 active">
                    <form method="post" class="autoCompleteAddress">
                    <div>
                    <vue-google-autocomplete
                    ref="mapplaceorderaddress"
                    id="mapplaceorderaddress"
                    classname="form-control"
                    placeholder="Please type your address"
                    v-on:placechanged="getAddressData"
                    @keypress.enter="$event.preventDefault()"
                    v-model="formatted_address"
                    types=""
                    :country="country_name"
                    >
                    </vue-google-autocomplete>
                    <div @click="getLocation()" class="location_icon">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 183.8 183.8" style="enable-background:new 0 0 183.8 183.8;" xml:space="preserve">
<g>
	<path d="M182.2,96.6c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0-0.1,0.1-0.1
		c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.8-0.1-1.5-0.4-2.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.6-0.7-1.1-1.2-1.5c-1.5-1.5-3.5-2.5-6-2.6c-0.1,0-0.2,0-0.3,0
		c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c-2.8-0.1-5.7-0.2-8.5-0.1c-0.4,0-0.8,0-1.2,0c-1,0-2.1,0-3.1,0c-1.3-0.2-2.1-0.9-2.6-2.1
		c0-0.1,0-0.2-0.1-0.2c-1.4-8.3-4-15.9-7.8-22.9c-2-3.6-4.2-7-6.8-10.1c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.6-0.7-0.8-1
		c-3.3-3.8-7-7.2-11.2-10.2c-0.3-0.2-0.5-0.4-0.8-0.6c-7.5-5.4-16-9.1-25.2-11.2c-1.6-0.4-3.2-0.8-4.8-1.1c-0.4-0.3-0.7-0.6-1-1
		c-0.4-0.5-0.6-1.1-0.8-1.7c0-0.9,0-1.8,0-2.6c0.1-3.3,0.1-6.5,0-9.8c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
		C99.6,5.6,98.6,3.5,97,2C97,2,97,2,97,1.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1,0-0.1-0.1-0.2-0.1C96,1.2,95.8,1.1,95.7,1c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0,0-0.1,0-0.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0C92,0,92,0,92,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
		c0,0,0,0-0.1,0c-0.8,0-1.5,0.1-2.2,0.4c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1
		c-0.6,0.3-1.1,0.7-1.5,1.2c-1.5,1.5-2.5,3.5-2.6,6c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0,0.5c-0.1,2.8-0.2,5.7-0.1,8.5
		c0,0.4,0,0.8,0,1.2c0,1,0,2.1,0,3.1c-0.2,1.3-0.9,2.1-2.1,2.6c-0.1,0-0.2,0-0.2,0.1c-8.3,1.4-15.9,4-22.9,7.8
		c-3.6,2-7,4.2-10.1,6.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.3-0.7,0.6-1,0.8c-3.8,3.3-7.2,7-10.2,11.2c-0.2,0.3-0.4,0.5-0.6,0.8
		c-5.4,7.5-9.1,16-11.2,25.2c-0.4,1.6-0.8,3.2-1.1,4.8c-0.3,0.4-0.6,0.7-1,1c-0.5,0.4-1.1,0.6-1.7,0.8c-0.9,0-1.8,0-2.6,0
		c-3.3-0.1-6.5-0.1-9.8,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.6,1.2-6.1,2.7c0,0-0.1,0.1-0.1,0.1
		C1.9,86.9,1.8,87,1.8,87c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2C1.2,87.8,1.1,88,1,88.2
		c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2
		c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
		c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.8,0.1,1.5,0.4,2.2c0,0,0,0.1,0,0.1c0.1,0.2,0.1,0.4,0.2,0.6
		c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.3c0.3,0.6,0.7,1.1,1.2,1.5c1.5,1.5,3.5,2.5,6,2.6c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
		c0.2,0,0.3,0,0.5,0c2.8,0.1,5.7,0.2,8.5,0.1c0.4,0,0.8,0,1.2,0c1,0,2.1,0,3.1,0c1.3,0.2,2.1,0.9,2.6,2.1c0,0.1,0,0.2,0.1,0.2
		c1.4,8.3,4,15.9,7.8,22.9c2,3.6,4.2,7,6.8,10.1c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.3,0.6,0.7,0.8,1c3.3,3.8,7,7.2,11.2,10.2
		c0.3,0.2,0.5,0.4,0.8,0.6c7.5,5.4,16,9.1,25.2,11.2c1.6,0.4,3.2,0.8,4.8,1.1c0.4,0.3,0.7,0.6,1,1c0.4,0.5,0.6,1.1,0.8,1.7
		c0,0.9,0,1.8,0,2.6c-0.1,3.3-0.1,6.5,0,9.8c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0.1,2.5,1.2,4.6,2.7,6.1
		c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0.1,0.2,0.1
		c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1
		c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.8,0,1.5-0.1,2.2-0.4
		c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.1,0.6-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.3-0.1c0.6-0.3,1.1-0.7,1.5-1.2
		c1.5-1.5,2.5-3.5,2.6-6c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0-0.5c0.1-2.8,0.2-5.7,0.1-8.5c0-0.4,0-0.8,0-1.2
		c0-1,0-2.1,0-3.1c0.2-1.3,0.9-2.1,2.1-2.6c0.1,0,0.2,0,0.2-0.1c8.3-1.4,15.9-4,22.9-7.8c3.6-2,7-4.2,10.1-6.8
		c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.3,0.7-0.6,1-0.8c3.8-3.3,7.2-7,10.2-11.2c0.2-0.3,0.4-0.5,0.6-0.8c5.4-7.5,9.1-16,11.2-25.2
		c0.4-1.6,0.8-3.2,1.1-4.8c0.3-0.4,0.6-0.7,1-1c0.5-0.4,1.1-0.6,1.7-0.8c0.9,0,1.8,0,2.6,0c3.3,0.1,6.5,0.1,9.8,0c0.1,0,0.3,0,0.4,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c2.5-0.1,4.6-1.2,6.1-2.7c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
		C182.1,96.7,182.1,96.7,182.2,96.6z M118.1,137.4c-7.6,4.1-16.5,6.4-26.5,6.6c-0.6,0.1-1.3,0.1-2,0.1c-9.3-0.6-17.6-3-24.7-7.1
		c-7.9-4.7-14.2-11.4-18.5-18.9c-4.1-7.6-6.4-16.5-6.6-26.5c-0.1-0.6-0.1-1.3-0.1-2c0.6-9.3,3-17.6,7.1-24.7
		c4.7-7.9,11.4-14.2,18.9-18.5c7.6-4.1,16.5-6.4,26.5-6.6c0.6-0.1,1.3-0.1,2-0.1c9.3,0.6,17.6,3,24.7,7.1
		c7.9,4.7,14.2,11.4,18.5,18.9c4.1,7.6,6.4,16.5,6.6,26.5c0.1,0.6,0.1,1.3,0.1,2c-0.6,9.3-3,17.6-7.1,24.7
		C132.3,126.7,125.6,133,118.1,137.4z"/>
	<path d="M117.4,86.9c-1-5.4-3.8-10.2-7.7-13.8c-4.6-4.4-10.8-7.2-17.6-7.2c-1.8,0-3.6,0.2-5.3,0.5c-5.4,1-10.2,3.8-13.8,7.7
		c-4.4,4.6-7.2,10.8-7.2,17.6c0,1.8,0.2,3.6,0.5,5.3c1,5.4,3.8,10.2,7.7,13.8c4.6,4.4,10.8,7.2,17.6,7.2c1.8,0,3.6-0.2,5.3-0.5
		c5.4-1,10.2-3.8,13.8-7.7c4.4-4.6,7.2-10.8,7.2-17.6C117.9,90.4,117.7,88.6,117.4,86.9z M99.8,98.4c-0.5,0.6-1.1,1.1-1.7,1.6
		c-1.7,1.2-3.8,1.9-6.3,1.9c-2.6,0-4.8-0.8-6.5-2.1c-0.6-0.5-1.1-1.1-1.6-1.7c-1.2-1.7-1.9-3.8-1.9-6.3c0-2.5,0.8-4.8,2.1-6.5
		c0.5-0.6,1.1-1.1,1.7-1.6c1.7-1.2,3.8-1.9,6.3-1.9c2.6,0,4.8,0.8,6.5,2.1c0.6,0.5,1.1,1.1,1.6,1.7c1.2,1.7,1.9,3.8,1.9,6.3
		C101.9,94.5,101.2,96.7,99.8,98.4z"/>
</g>
</svg>
                    </div>
                    </div>
                    </form>
                      </div>
                    </div>
                    
                    <div class="form-row">
                      <div class="form-group col-md-12 active">
                        <label :style="textcolor" class="control-label" for="address">
                          Apt Number
                        </label>
                        <input
                          type="text"
                          name="address"
                          class="form-control"
                          id="address"
                          v-model="address"
                          :class="{error: errors.has('address')}"
                          maxlength="70"
                        >
                        <span
                          v-show="errors.has('address')"
                          class="help is-danger"
                        >{{ $entrallfldrqrd }}</span>
                      </div>
                    </div>
                    
                    <div class="form-group">
                      <div class="form-group col-md-12 d-flex flex-wrap justify-content-center">
                        <div
                          class="radio-dt"
                          v-for="(location,index) in location_types"
                          v-bind:key="index"
                        >
                          <input
                            type="radio"
                            :id="location.val"
                            v-model="location_type"
                            :value="index"
                            @click="selectLocationType(location.id)"
                          >
                          <label :for="location.val">{{location.val}}</label>
                        </div>
                      </div>
                    </div>

                    <div class="form-row" v-if="other_location">
                      <div class="form-group col-md-12 active">
                        <label :style="textcolor" class="control-label" for="tag">Tag</label>
                        <input type="text" name="tag" class="form-control" id="tag" v-model="tag">
                      </div>
                    </div>

                    <div class="form-group text-center">
                      <button :style="colorObj" :disabled="enablesavesucadrsmesg" class="btn add-btn hoverEffect">Add</button>
                    </div>
                  </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div
      class="modal fade"
      id="OrderConfirmationModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="OrderConfirmationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body" v-if="getcartdata != null && getcartdata.status == '1'">
            <div class="bottom-section">
              <h2 class="section-heading text-center">Order Confirmation</h2>
              <div class="conformation-section">
                <p>Do you want to send your order to {{getcartdata.data.store.store_name}}?</p>
                <div class="price row mx-0 justify-content-between align-items-center">
                  <p>Total Amount</p>
                  <p><span v-html="priceFormatter(grandTotal)"></span></p>
                </div>
                <span v-if="(payment_method == 1 ||payment_method == 2) && (is_wallet_applied==0 ? (Number(grandTotal) > 0) : (Number(grandTotal)-(Number(grandTotal) > Number(getcartdata.data.wallet_balance)?(Number(getcartdata.data.wallet_balance) > Number(wallet_max_amount) ? wallet_max_amount : getcartdata.data.wallet_balance):(Number(grandTotal) > Number(wallet_max_amount) ? wallet_max_amount : grandTotal)))) ">
                <div class="price row mx-0 justify-content-between align-items-center">
                  <p>Payment Due on Delivery</p>                                                                                                                                                                                                                                                                                                          
                  <p>
                    <span v-if="is_wallet_applied==1" v-html="priceFormatter(Number(grandTotal)-(Number(grandTotal) > Number(getcartdata.data.wallet_balance)?(Number(getcartdata.data.wallet_balance) > Number(wallet_max_amount) ? wallet_max_amount : getcartdata.data.wallet_balance):(Number(grandTotal) > Number(wallet_max_amount) ? wallet_max_amount : grandTotal)))"></span>
                    <span v-else v-html="priceFormatter(Number(grandTotal))"></span>
                  </p>
                </div>
                </span>
                <div class="adress">
                  <div class="row">
                    <div class="col-sm-10 col-8">
                      <div class="adress-details" v-if="delivery_pickup == 1">
                        <span class="status">Deliver To</span>
                        <h3>{{user_address_alias}}</h3>
                        <p class="addr">{{user_address}}</p>
                      </div>
                      <div class="adress-details" v-else>
                        <span v-if="iseatry == 1" class="status">Take Away From</span>
                        <span v-if="iseatry != 1" class="status">Pickup From</span>
                        <p class="addr">{{store_address}}</p>
                      </div>
                    </div>
                    <div class="col-sm-2 col-4 send-location">
                      <div v-if="delivery_pickup != 1">
                        <a :href="store_map_link" class="btn" target="_blank"><img src="../../assets/images/ic_target.jpg" alt="location"></a>
                      </div>
                    </div>
                  </div>
                  <div class="payment-mode">
                    <p>Payment mode: {{selectedpaymentmethodname}}
                    </p>
                  </div>
                </div>
                <div class="row mx-0 justify-content-center button-row">    
                  <button type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                  <button :disabled='btnPlaceOrder' :class="btnPlaceOrder ? 'disablebtn' : ''" type="button" :style="colorObj" class="btn button-style hoverEffect" @click="placeOrder('click')">Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



    <div
      class="modal fade"
      id="ManageAddressModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ManageAddressModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body" v-if="getcartdata != null && getcartdata.status == '1'">
            <span class="change-address">Change Address</span>
             <div class="card-block col-12">
                      <div class="address-book" v-if="address_loading==false">
                        <div class="row">
                          <div class="col-md-4 col-sm-6 address-book-wrap">
                            <h3 class="delivery mb-0 w-100">Deliver to</h3>
                            <div class="address d-flex justify-content-center align-items-center flex-wrap">
                              <div class="add-button">
                                <button
                                  type="button"
                                  class="d-block mx-auto add-btn"
                                  @click="openAddAddressPopup()"
                                >
                                  <span class="d-block">
                                    <img src="../../assets/images/svgs/plus.svg" alt="plus" class="d-block">
                                  </span>
                                </button>
                                <h6 class="mt-3">Add Address</h6>
                              </div>
                            </div>
                          </div>
                          <div
                            :class="
                              (popup_address_type == 1 ? address.is_default == '1' :address.customer_address_id==billing_address_id)
                                ? 'col-md-4 col-sm-6 address-book-wrap active'
                                : 'col-md-4 col-sm-6 address-book-wrap'
                            "
                            v-for="(address,index) in getaddressdetails.data"
                            v-bind:key="index"
                            v-show="getaddressdetails != '' && getaddressdetails.data != ''"
                          >     
                              
                            <h3 class="delivery mb-0 w-100">{{change_address_mode}}</h3>
                            <div
                              class="address"
                              @click="setDeliveryAddress(address.customer_address_id, address)"
                              style="cursor: pointer;"
                            >
                              <div class="address-one w-100">
                                <h5>{{ address.alias }}<span class="incomplete-address">{{address.address2 != "" ? '' : '(Incomplete Address)'}}</span></h5>
                                <p>
                                  <span v-if="address.address2">{{ address.address2 }}, </span>
                                  <span v-if="address.address">{{ address.address }},<br></span>
                                  <span v-if="address.area_name">{{ address.area_name }},<br></span>
                                  <span v-if="address.city_name">{{ address.city_name }},<br></span>
                                  <span v-if="address.state_name">{{ address.state_name }} {{ address.zipcode }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container" v-if="address_loading==true">
                        <p class="text-center">
                          <img src="../../assets/images/loading.gif">
                          <br/>
                         Do not press the back button or exit the browser.
                        </p>
                      </div>
                    </div>
          </div>
        </div>
      </div>

    </div>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import ApplyOffer from "../offer/ApplyOffer";
import Avatar from "vue-avatar-component";
import OfferDetail from "../offer/OfferDetail";
import moment from "moment-timezone";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { bus } from '../../main';
import helper from '../../store/helper';
import localconfig from "../../global_settings.json"
import axios from "axios";
import validationmessage from "../checkout/ValidationMessage";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: "PlaceOrder",
  components: {
    Defaultlayout,
    ApplyOffer,
    OfferDetail,
    Avatar,
    'vue-google-autocomplete': VueGoogleAutocomplete,
    DatePicker
  },
    props: {
    errorMessage:{
      type: String
    }
  },
  mixins:[helper],
  data() {
    return {
      isDeletePopupVisible: false,
      format:"DD-MM-YYYY",
      loading: true,
      country_filter_code:'',
      address_loading: true,
      order_details_loading: true,
      isAutoCompleteDataSelected:false,
      currency_symbol: "₹",
      userprofile: null,
      custprodctdetail: {
        user_id: '',
        cart_type: '',
        timezone: '',
        offer_id: '',
        offer_discount: '',
        item_total: '',
      },
      delivery_now_later: 1,
      getcartdata: [],
      getcartdetail: [],
      getaddressdetails: [],
      getbillingaddressdetails:[],
      getaddressstatus: [],
      profile: {
        user_id: ''
      },
      getOfferlistdata: null,
      customeraddres: {
        customer_address_id: '',
        user_id: '',
        is_default: '',
        alias: '',
        alias_code: '',
        address1: '',
        address2: '',
        zipcode1: '',
        street: '',
        remarks:"",
        country:"",
        state:"",
        city:"",
        latitude:"",
        longitude:"",
      },
      remarks: '',
      custshopcatgryoffrs: {
        user_id: '',
        store_id: '',
        is_store_listing: '',
      },
      custcartremve: {
        user_id: '',
        cart_type: ''
      },
      custitemsplce: [],
      createcustomerchat : {
        user_id:"",
        order_id:""
      },
      custordrdelvryplace: {
        user_id: '',
        store_id: '',
        date: '',
        items: [],
        amount: '',
        remarks: '',
        order_type: '',
        payment_method_id: '',
        is_scheduled: '',
        delivery_charges: '',
        delivery_slot_from: '',
        delivery_slot_to: '',
        pickup_slot_from: '',
        pickup_slot_to: '',        
        scheduled_delivery_date: '',
        offer_code: '',
        offer_discount: '',
        call_back_url:'',
        transactionid:'',
        packaging_cost:'',
        order_tax:'',
        source:'1',
        pay_thru_wallet:'',
        wallet_amount:'',
        order_prescription_images:[],
        billing_address_id:'',
        card_name: '',
        card_number: '',
        expiration_date: '',
        card_code: '',
        item_total: '',
        customer_profile_id:"",
        payment_profile_id:""
      },
      validate_coupon: {
        user_id: '',
        store_id: '',
        discount_code: '',
        amount: '',
        order_type: '',
        payment_method: '',
        delivery_slot:'',
        items:[]
      },
      appliedoffrcode: '',
      appliedoffrtitle: '',
      appliedoffrremarks:'',
      payment_method: '',
      selected_slot: '',
      selected_pickup_slot:'',
      delivery_pickup: 1,
      offer_discount: 0,
      scheduled_date: '',
      scheduled_time: '',
      scheduled_pickup_date: '',
      scheduled_pickup_time: '',
      store_business_hours: null,
      store_business_hours_data: {
        store_id: '',
      },
      store_business_days: null,
      store_business_days_data: {
        store_id: '',
      },
      user_address: '',
      user_address_alias: '',
      enablesavesucadrsmesg: false,
      getsaveaddressmsg: '',
      enableerradrsmessage: false,
      geterraddrssmsg: '',
      areas: null,
      cities: null,
      states:null,
      countries:null,
      default_address: '1',
      houseapprt: '',
      streetname: '',
      getarea: '',
      cityname: '',
      pincode: '',
      loctntype: '',
      location_types:[
        {"id":"1","txt":"Home,0","val":"Home"},
        {"id":"2","txt":"Office,1","val":'Office'},
        {"id":'3',"txt":"Other,2","val":'Other'}
      ],
      statebody: {
        country: "",
      },
      citybody: {
        state: ""
      },
      area: {
        city: ''
      },
      other_location: false,
      tag: '',
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      delivery_charges_body: {
        user_id: "",
        store_id: "",
        order_amount: "",
        lat: "",
        long: "",
      },
      delivery_charges: null,
      location_details: {
        customer_address_id: "",
        lat: "",
        lng: "",
        address: "",
      },
      delivery_charge_msg: "",
      ccname: '',
      ccnumber: '',
      ccexpiration: '',
      cccvv: '',
      store_address: '',
      store_map_link: '',
      todayDate: moment(new Date()).format('YYYY-MM-DD'),
      customer_address: {
        customer_address_id: "",
        user_id: "",
        is_default: "",
        alias: "",
        alias_code: "",
        address1: "",
        address2: "",
        zipcode1: "",
        street:"",
        latitude:"",
        longitude:"",
        remarks:"",
        country:"",
        state:"",
        city:""
      },
      location_type: "0",
      address: "",
      address2: "",
      country_id:"",
      city_id: "",
      state_id: "",
      area_id: "",
      markers:[],
      center: { lat:23.0225, lng:72.5714},
      latitude:23.0225,
      longitude:72.5714,
      formatted_address:"",
      wallet_max_amount:0,
      is_wallet_applied:false,
      updated_latitude:23.0225,
      updated_longitude:72.5714,
      country_name:"",
      modules:{
        is_wallet:''
      },
      selectedpaymentmethodname:'',
      countries_name: [],
      is_alpha_numeric:"",
      pincode_length:0,
      new_order_imagecount : 0,
      is_business_model:0,
      iseatry:0,
      default_address_visiblity:true,
      show_billing_address:false,
      change_address_mode : "Deliver to",
      popup_address_type : 1,
      use_google_map : 1,
      zoomProperty : 18,
      btnPlaceOrder : false,
      areabody: {
        city: '',
      },
      show_price_same_font:1,
      open_time:null,
      close_time:null,
      is_open:false,
      offer_id: '',
      item_total: 0,
      zipcode1:"",
      street:"",
      country:"",
      state:"",
      city:"",
      save_cards:{
        user_id:"",
        page:1
      },
      save_cards_status:0,
      save_cards_data:[],
      customer_card_id:0,
      customer_profile_id:"",
      payment_profile_id:"",
      add_newcard:true,
      delete_card:{
        customer_card_id:"",
        user_id:""
      },
      amount_for_signature:"",
      amount_for_id_proof:"",
      signature:"",
      id_proof:"",
      profiledata: null,
      user_picture: "",
      selectedIdProof: '',
      imagePreview: null,
      customer_account:{
        user_id: "",
      },
      zone_days: "",
      check_lat :'',
      check_long :'',
    };
  },
  computed: {
    datePickerFormat: function(){
      var dateFormat = localStorage.getItem("date_format");
      if(dateFormat == "DD-MM-YYYY HH:mm:ss"){
        return "DD-MM-YYYY";
      } else {
        return "MM-DD-YYYY";
      }
    },
    itemTotal: function() {
      var sum = 0;
      if (this.getcartdata.status != 0) {

        this.getcartdata.data.items.forEach(items => {
          var item_variants = items.item_variants.filter(
            function(elem) {
              if (elem.is_added == "1") return elem;
            }
          );

          item_variants.forEach(item_variant => {
            
            sum += parseFloat(item_variant.discounted_price) * parseFloat(item_variant.quantity);

            if(item_variant.choices != null) {
              item_variant.choices.forEach(choice => {
                choice.choice_prices.forEach(choice_price => {
                    if(choice_price.is_added == 1){
                      sum += parseFloat(choice_price.price) * parseFloat(item_variant.quantity);
                    }
                });
              });
            }

            if(item_variant.addons != null) {
              item_variant.addons.forEach(addon => {
                addon.addon_prices.forEach(addon_price => {
                    if(addon_price.is_added == 1){
                      sum += parseFloat(addon_price.price) * parseFloat(item_variant.quantity);
                    }
                });
              });
            }
          });

          if(items.combo_variants != null){
            items.combo_variants.forEach(combo_variant => {

              if(combo_variant.choices != null){
                combo_variant.choices.forEach(choice => {
                  choice.choice_prices.forEach(choice_price => {
                    if(choice_price.is_added == 1){
                      sum += parseFloat(choice_price.price);
                    }
                  });
                });
              }

              if(combo_variant.addons != null){
                combo_variant.addons.forEach(addon => {
                  addon.addon_prices.forEach(addon_price => {
                    if(addon_price.is_added == 1){
                      sum += parseFloat(addon_price.price);
                    }
                  });
                });
              }

            });
          }

        });
      }
      return parseFloat(sum).toFixed(2);
    },
    grandTotal: function() {
      var totalsum = 0;
      var newsum = this.itemTotal;

      if (this.delivery_charges != null && this.delivery_pickup == 1) {
        totalsum = parseFloat(newsum) + parseFloat(this.delivery_charges.data.delivery_charges);
      } else {
        totalsum = newsum;
      }

      if(this.offer_discount){
        totalsum = parseFloat(totalsum) - parseFloat(this.offer_discount);
      }

      if((parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost)).toFixed(2)  != '0.00')
      {
        totalsum = parseFloat(totalsum) + parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost);
      }
      return parseFloat(totalsum).toFixed(2);
    },
    paymentMethods: function(){
      var payment_methods = null;
      if (this.getcartdata.status != 0) {
        payment_methods = this.getcartdata.data.store_payments;
        return payment_methods = payment_methods.filter(function(item) {
          return item.payment_method_id !== 3 && item.is_added == 1
        });
      }
  
      return payment_methods;
    },
  },
  filters: {
    formatDate: function(value) {
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      let date_str = moment(value,'DD-MM-YYYY HH:mm:ss');
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return date_str.format('MM-DD-YYYY HH:mm');
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return date_str.format('DD-MM-YYYY HH:mm');
      }
    },
    formatConfigDate: function(date){
      var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : localconfig.timezone;
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      var utc_date = moment.utc(date, 'DD-MM-YYYY');
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return moment.tz(utc_date,timezone).format('MM-DD-YYYY');
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return moment.tz(utc_date,timezone).format('DD-MM-YYYY');
      }
    },
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
      // Logout user when account is deleted from mobile app
      this.isAccountDeleted();
    } else {
      this.userprofile = null;
    }
    this.loading=true;
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    this.delivery_pickup = localStorage.getItem('delivery_pickup');
    this.wallet_max_amount = localStorage.getItem("walletmaxamount");
    if(localStorage.getItem("Is_wallet_Applied") != null && localStorage.getItem("Is_wallet_Applied") != undefined)
    {
      if(localStorage.getItem("Is_wallet_Applied") == "walletapplied")
      {
        this.is_wallet_applied = true;
        localStorage.removeItem("Is_wallet_Applied");
      }
    }
    this.getCart();
    this.getUserAddress();
    if(localStorage.getItem("billing_address_id") != null && localStorage.getItem("billing_address_id") != undefined)
    {
      this.billing_address_id = localStorage.getItem("billing_address_id");
      localStorage.removeItem("billing_address_id");
    }

    this.showGMap();
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0) {
        this.country_filter_code = JSON.parse(data).country;
        this.statebody.country = JSON.parse(data).country;        
        this.show_billing_address = JSON.parse(data).show_billing_address;
        this.is_business_model = JSON.parse(data).business_model;
        this.is_alpha_numeric = JSON.parse(data).allow_alphanumeric_pincode;
        this.pincode_length = JSON.parse(data).pincode_length;
        this.use_google_map = JSON.parse(data).use_google_map;
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
        this.modules.is_wallet = JSON.parse(data).allow_wallet;
        this.delivery_address_note = JSON.parse(data).delivery_address_note;
        this.billing_address_note = JSON.parse(data).billing_address_note;
        this.id_proof_notes = JSON.parse(data).id_proof_notes;
        this.amount_for_signature = JSON.parse(data).amount_for_signature;
        this.amount_for_id_proof = JSON.parse(data).amount_for_id_proof;
        this.country_name = JSON.parse(data).country_codes;
        if (this.delivery_pickup == 1) {
          this.getCountries();
        }
      } else {
        this.use_google_map = localconfig.use_google_map;
        this.country_filter_code = JSON.parse(data).country;
        this.statebody.country = JSON.parse(data).country;
        this.show_billing_address = JSON.parse(data).show_billing_address;
        this.is_business_model = localconfig.business_model;
        this.country_name = localconfig.country_codes;
        if (this.delivery_pickup == 1) {
          this.getCountries();
        }
      }
      this.country_name = this.country_name.split(",");
    });
    this.originalColor();
    document.body.classList.add('top-navbar');
    window.$('<style>.address-form .radio-dt input[type=radio]:checked ~ label { color: '+localStorage.getItem("css_button_customer_default_font")+'; }</style>').appendTo('body');
    window.$('<style>.address-form .radio-dt input[type=radio]:checked ~ label { background-color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('<style>.address-form .radio-dt label:hover { background-color: '+localStorage.getItem("button_hover_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .radio-dt label:hover { color: '+localStorage.getItem("css_button_customer_hover_font")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-top-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-right-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox label:before { border-left-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.address-form .form-group.checkbox input:checked + label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    this.use_google_map = parseFloat(localStorage.getItem("use_google_map"));
    if(this.use_google_map == 1) {
      this.getLocation();
    }
    this.country_name = localStorage.getItem("country_codes");
    this.country_name = this.country_name.split(",");
    this.getSaveCard();
  },
  updated(){
    window.$('<style>.delivery-method .radio-dt input ~ label:before { border-top-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.delivery-method .radio-dt input ~ label:before { border-right-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.delivery-method .radio-dt input ~ label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.delivery-method .radio-dt input ~ label:before { border-left-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.delivery-method .radio-dt input ~ label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('.location_icon svg path').css({fill: localStorage.getItem("button_default_color")});
  },
  methods: {
    isAccountDeleted() {
      this.customer_account.user_id = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0";
      this.$store.dispatch("custaccountstatus", this.customer_account).then(response => {
        if(response.status == 2) {
          // Remove user's local storage and redirect to homepage
          localStorage.removeItem("user");
          window.location.href="/";
        }
      });
    },
    disabledBeforeToday(date){
      let get_schedule_date_time, get_schedule_date_only;
      if(this.delivery_pickup == 1 && this.getcartdata.data.schedule_delivery_date != null){
        get_schedule_date_time = this.getcartdata.data.schedule_delivery_date.split(":")[0];
      } else if(this.getcartdata.data.schedule_pickup_date != null) {
        get_schedule_date_time = this.getcartdata.data.schedule_pickup_date.split(":")[0];
      }
      get_schedule_date_only = get_schedule_date_time.split(" ")[0];

      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return moment(date).format('MM-DD-YYYY') < get_schedule_date_only;
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return moment(date).format('DD-MM-YYYY') < get_schedule_date_only;
      }
    },
    async getLocation() {
			try {
				await this.$getLocation({
					enableHighAccuracy: true
				}).then(coordinates => {
          this.latitude = parseFloat(coordinates.lat);
          this.longitude = parseFloat(coordinates.lng);
          this.updated_latitude = parseFloat(coordinates.lat);
          this.updated_longitude = parseFloat(coordinates.lng);
          this.center.lat = parseFloat(coordinates.lat);
          this.center.lng = parseFloat(coordinates.lng);
          this.showGMap()
          this.getStreetAddressFrom(coordinates.lat,coordinates.lng,true)
				});

			} catch (error) {
         console.log(error)
         this.latitude = parseFloat(localStorage.getItem("default_location_lat"));
         this.longitude = parseFloat(localStorage.getItem("default_location_long"));
         this.updated_latitude = parseFloat(localStorage.getItem("default_location_lat"));
         this.updated_longitude = parseFloat(localStorage.getItem("default_location_long"));
         this.center.lat = parseFloat(localStorage.getItem("default_location_lat"));
         this.center.lng = parseFloat(localStorage.getItem("default_location_long"));
			}
		},
    async getStreetAddressFrom(lat, long,formatedFlag) {
      var _this=this;
      this.state_id = '';
      this.city_id = '';
      this.area_id = '';
      this.address2 = '';
      this.pincode = ''; 
            
			try {
				var {
					data
				} = await axios.get(
				"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
				lat +
				"%2C" +
				long +
				"&key="+((localStorage.getItem("google_map_key")!=null && localStorage.getItem("google_map_key")!="" && localStorage.getItem("google_map_key")!=undefined) ? localStorage.getItem("google_map_key") : 'AIzaSyDU1yuRqsrT2TFdn5srqr5F9iDzdDqzVL4')
				);
				if (data.error_message) {
					console.log(data.error_message)
				} else {
          
              if (formatedFlag)
              {
                  _this.formatted_address = data.results[0].formatted_address;
                  window.$('#mapplaceorderaddress').val(data.results[0].formatted_address)
              }

              let returnData = {};
              const ADDRESS_COMPONENTS = {
                  subpremise : 'short_name',
                  street_number: 'short_name',
                  route: 'long_name',
                  locality: 'long_name',
                  administrative_area_level_1: 'long_name',
                  administrative_area_level_2: 'long_name',
                  country: 'long_name',
                  postal_code: 'short_name'
              };

              for (let i = 0; i < data.results[0].address_components.length; i++) {
                  let addressType = data.results[0].address_components[i].types[0];

                  if (ADDRESS_COMPONENTS[addressType]) {
                      let val = data.results[0].address_components[i][ADDRESS_COMPONENTS[addressType]];
                      returnData[addressType] = val;
                  }
              }
              this.street = returnData.street_number+" "+returnData.route;
              this.address2 = data.results[0].formatted_address;
              this.zipcode1 = returnData.postal_code;
              this.country = returnData.country;
              this.state = returnData.administrative_area_level_1;
              this.city = returnData.locality;
				}
			} catch (error) {
				console.log(error);
			}
		},
    filterComboAddons(addons){
      var tmp_addons = [];
      addons.forEach(addon => {
        addon.addon_prices.forEach(addon_price => {
          if(addon_price.is_added == 1){
            var obj = {};
            obj['variant_addon_name'] = addon.variant_addon_name;
            obj['variant_sub_addon_name'] = addon_price.variant_sub_addon_name;
            tmp_addons.push(obj);
          }
        });
      });
     return tmp_addons;
    },
    filterComboChoices(choices){
      var tmp_choices = [];
      choices.forEach(choice => {
        choice.choice_prices.forEach(choice_price => {
          if(choice_price.is_added == 1){
            var obj = {};
            obj['variant_choice_name'] = choice.variant_choice_name;
            obj['variant_sub_choice_name'] = choice_price.variant_sub_choice_name;
            tmp_choices.push(obj);
          }
        });
      });
     return tmp_choices;
    },
    fileNameChanged(evt) {
      var file = evt.currentTarget.files[0];
      var extension = '';
      if (file.size / 1024 / 1024 > 5) {
        this.$toast.error('Image size should be less than 5MB.');
        return false;
      }
      if (file.name.indexOf('.') > -1) 
      {
        extension = file.name.split(".").pop().toLowerCase();
      }
      else 
      {
        extension = file.name.split('/').pop().toLowerCase();
      }
      if (window.$.inArray(extension, ['png', 'PNG', 'jpg', 'jpeg']) == -1) 
      {
        this.$toast.error('Please Select JPG,JPEG And PNG format for user picture');
        return false;
      } 
      else 
      {
        this.custordrdelvryplace.order_prescription_images.push(file);
        this.new_order_imagecount++;
      }
      window.$('#LogoInput').val(null);
    },
    uploadimage() 
    {
      window.$('#LogoInput').click();
    },
    removeImage(i)
    {
      this.custordrdelvryplace.order_prescription_images.splice(i, 1); //remove image from selected image array
    },
    updateCoordinates(location) {
      this.latitude = parseFloat(location.latLng.lat());
      this.longitude = parseFloat(location.latLng.lng());
      this.getStreetAddressFrom(location.latLng.lat(),location.latLng.lng(),true)      
    },
    getAddressData(addressData) {
      this.isAutoCompleteDataSelected=true;
      this.$refs.addAddressMap.$mapPromise.then((map) => {
        map.setZoom(18)
      })

      this.latitude = parseFloat(addressData.latitude);
      this.longitude = parseFloat(addressData.longitude);
     
      window.$("#mapplaceorderaddress").val(addressData.street_number+" "+addressData.route+", "+addressData.locality+", "+addressData.administrative_area_level_1+" "+addressData.postal_code+", "+addressData.country)
      this.address2 = window.$("#mapplaceorderaddress").val();
			
      this.street = addressData.street_number+" "+addressData.route;
      this.zipcode1 = addressData.postal_code;
      this.country = addressData.country;
      this.state = addressData.administrative_area_level_1;
			this.city = addressData.locality;
      
      this.showGMap();
    },
    async showGMap() {
      this.showModal = true;
      const marker = { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) };
      this.markers =[];
      this.markers.push({ position: marker });
      this.center = marker;    
    },
       ShowTitle(){
      window.$('.tax-and-charges').tooltip({ trigger: 'click' });
    },
    goBack(){
      this.$router.go(-1);
    },
    getMaxOrderValue(){
      if (
        this.getcartdata.data.payment_methods.find(
          o => o.payment_method_id == this.payment_method
        ) != undefined
      ) {

        var max_order_value = this.getcartdata.data.payment_methods.find(
            o => o.payment_method_id == this.payment_method
          ).max_order_value;

        if (parseFloat(max_order_value) > 0 && parseFloat(max_order_value) < parseFloat(this.grandTotal)) {
          return max_order_value;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    selectLocationType(location_id){
      if(location_id == 3){
        this.other_location = true;
      } else {
        this.other_location = false;
      }
    },
    async getCountries(){
      this.countries = this.country_filter_code;
      this.countries_name = null;
      this.$store.dispatch("getcountries", this.countries).then(response => {
        if(response.status == 1){
          this.countries_name = JSON.parse(JSON.stringify(response.data));
        }
      });
    },
    getStates() {
      this.statebody.country = this.country_id;
      this.states = null;
      this.$store.dispatch("getstates", this.statebody).then(response => {
        if(response.status == 1){
          this.states = JSON.parse(JSON.stringify(response.data));
          this.state_id= ""; 
          this.city_id = "";
          this.area_id="";
        }
      });     
    },
    getCities(){
      this.citybody.state = this.state_id
      this.cities = null;
      this.$store.dispatch("getcities", this.citybody).then(response => {
        if(response.status == 1){
          this.cities = JSON.parse(JSON.stringify(response.data));
        }
      })
    },
    getAreas(){
      this.area.city = this.city_id;
      this.areas = null;
      this.$store.dispatch('getarealist',this.area).then(response => {
        if(response.status == 1){
          this.areas = JSON.parse(JSON.stringify(response.data));
        }
      });
    },
    addAddress() {
      if (!((this.zipcode1 != null && this.zipcode1.indexOf('undefined') <= -1) && (this.city != null && this.city.indexOf('undefined') <= -1) && (this.state != null && this.state.indexOf('undefined') <= -1) && (this.country != null && this.country.indexOf('undefined') <= -1) && (this.street != null && this.street.indexOf('undefined') <= -1) )){
        this.$toast.error("Select valid address with Street number Name, City, State and Country.")
        return false;
      }
      this.enablesavesucadrsmesg = true;
      this.$validator.validate();
      this.$validator.validateAll().then(result => {
        if (result && this.isAutoCompleteDataSelected) {
          this.customer_address.user_id = this.userprofile != null ? this.userprofile.user_id : "0";
          this.customer_address.is_default = this.default_address == true ? "1" : "0";

          if (this.location_type == "2" && this.tag != "") {
            this.customer_address.alias = this.tag;
          } else {
            this.customer_address.alias = this.location_types[this.location_type].val;
          }

          this.customer_address.alias_code = this.location_type;
          this.customer_address.address1 = this.address;
          this.customer_address.address2 = this.address2;
          this.customer_address.address2 = this.address2;
          this.customer_address.country = this.country;
          this.customer_address.state = this.state;
          this.customer_address.city = this.city;
          this.customer_address.zipcode1 = this.zipcode1;
          this.customer_address.street = this.street;
          this.customer_address.remarks = "";
          this.customer_address.latitude = this.latitude;
          this.customer_address.longitude = this.longitude;

          this.$store
            .dispatch("managecustomeraddrs", this.customer_address)
            .then(response => {
              if (JSON.parse(JSON.stringify(response)).status == "1") {
                this.enablesavesucadrsmesg = true;
                this.getsaveaddressmsg = JSON.parse(
                  JSON.stringify(response)
                ).message;
                setTimeout(() => (this.enablesavesucadrsmesg = false), 3000);
                this.getUserAddress();
                this.address = "";
                this.address2 = "";
                this.state_id = "";
                this.city_id = "";
                this.area_id = "";
                this.pincode = null;
                this.default_address = false;
                this.location_type = "0";
                this.other_location = false;
                this.tag = "";
                this.$validator.reset();
                this.getCart();
                window.$("#address-pop-up").modal("hide");
                this.$toast.success("Address added successfully");
                window.$("#ManageAddressModal").modal("hide");
              } else {
                this.enableerradrsmessage = true;
                this.geterraddrssmsg = JSON.parse(
                  JSON.stringify(response)
                ).message;
                setTimeout(() => (this.enableerradrsmessage = false), 3000);
              }
            });
        }
        else{
          this.enablesavesucadrsmesg=false;
          this.$toast.error("Select address from autocomplete");
        }
      });
    },
    getStoreBusinessHours(){
      this.store_business_hours_data.store_id = this.getcartdata.data.store.store_id;
      this.$store
        .dispatch("getstorebusinesshours", this.store_business_hours_data)
        .then(response => {
          if(response.status == 1){
            this.store_business_hours = response.data;
            window.$('#BusinessHoursModal').modal("show");
          }
        });
    },
    getDeliveryType(delivery_type_id){
      let delivery_type = '';
      switch (delivery_type_id) {
        case "1":
          delivery_type = 'Delivery';
        break;
        case "2":
          if(this.iseatry == 1){
            delivery_type = 'Take Away';  
          } else {
            delivery_type = 'Pickup';
          }
        break;
      }
      return delivery_type;
    },
    addDeliveryAddress(){
      window.$('#address-pop-up').modal('show');
      this.default_address_visiblity = true;
    },
    addBillingAddress(){
      window.$('#address-pop-up').modal('show');
      this.default_address_visiblity = false;
    },
    priceFormatter(value) {
      if(value){
        value = parseFloat(value).toFixed(2);
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var decimal_font_size = this.show_price_same_font;
        var response = this.currency_symbol + price;
        if(decimal != undefined){
          if(decimal_font_size == 1){
            response += "<span>." + decimal + "</span>";
          } else {
            response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
          }
        } else {
          if(decimal_font_size == 1){
            response += "<span>.00</span>";
          } else {
            response += "<span class='price-decimal'><small>.00</small></span>";
          }
        }
        return response;
      } else {
        return this.currency_symbol + '0';
      }
    },
    validateOfferCode(){
      if (this.appliedoffrcode != '') {
        this.applyOfferCode(this.appliedoffrcode, '');
      }
    },
    removeOfferCode(){
      this.appliedoffrcode = '';
      this.appliedoffrtitle = '';
      this.appliedoffrremarks = '';
      this.offer_discount = 0;
      this.offer_id = '';
      this.getCart();
    },
    applyOfferCode(offercode, offertitle) {
      if(this.delivery_pickup == 1){
          if(this.delivery_now_later == 1 && this.getcartdata.data.order_settings.delivery_slots != null && this.getcartdata.data.order_settings.delivery_time_type == 1){
            if(this.selected_slot == '' && this.selected_slot != '0'){
                this.appliedoffrcode = '';
                this.appliedoffrtitle = '';
                this.offer_id = '';
                this.offer_discount = 0;
                this.item_total = 0;
                this.appliedoffrremarks ='';
                this.$toast.error("Please select the Delivery Slot");
                return false;
              }
          }
      } 
      this.offer_discount = 0;
      this.order_details_loading = true;
      this.appliedoffrcode = offercode;
      this.appliedoffrtitle = offertitle;
      this.validate_coupon.user_id = this.getLoginUserId();
      this.validate_coupon.store_id = this.getcartdata.data.store.store_id;
      this.validate_coupon.discount_code = offercode;
      this.validate_coupon.amount = this.itemTotal;
      this.validate_coupon.order_type = this.delivery_pickup;
      this.validate_coupon.payment_method = this.payment_method;
      this.validate_coupon.delivery_slot = JSON.stringify(this.getcartdata.data.order_settings.delivery_slots[this.selected_slot]);
      this.validate_coupon.items = JSON.stringify(this.custitemsplce);
      this.$store
        .dispatch("getcustvalidatecoupon", this.validate_coupon)
        
        .then(response => {
          if (response.status == 0) {
            this.appliedoffrcode = '';
            this.appliedoffrtitle = '';
            this.offer_id = '';
            this.offer_discount = 0;
            this.item_total = 0;
            this.appliedoffrremarks = '';
            this.$toast.error(response.message);
          }
           else {
            if(response.data.sub_offer_type_id == 15 && this.delivery_charges != null){
              if(parseFloat(this.delivery_charges.data.delivery_charges) < parseFloat(response.data.offer_discount)){
                this.appliedoffrcode = '';
                this.appliedoffrtitle = '';
                this.offer_id = '';
                this.offer_discount = 0;
                this.item_total = 0;
                this.appliedoffrremarks = '';
                this.$toast.error("Offer can not be applied as your cart already has lesser delivery charges");
              }
               else {
                if(response.data.offer_remarks != "")
                  this.appliedoffrremarks = response.data.offer_remarks;
                this.offer_id = response.data.offer_id;
                this.offer_discount = parseFloat(this.delivery_charges.data.delivery_charges) - parseFloat(response.data.offer_discount);
                this.offer_discount = parseFloat(this.offer_discount).toFixed(2);
                this.item_total = this.itemTotal;
                this.$toast.success(response.message);
                this.getCart();
              }
            } 
 
              else if(response.data.sub_offer_type_id == 15 && this.delivery_pickup != 1){
              this.appliedoffrcode = '';
              this.appliedoffrtitle = '';
              this.offer_id = '';
              this.offer_discount = 0;
              this.item_total = 0;
              this.appliedoffrremarks ='';
              this.$toast.error("Offer can not be applied on pickup order");
            }
              else {
              if(response.data.offer_remarks != "")
                this.appliedoffrremarks = response.data.offer_remarks;
                this.offer_id = response.data.offer_id;
                this.offer_discount = parseFloat(response.data.offer_discount).toFixed(2);
                this.item_total = this.itemTotal;
                this.$toast.success(response.message);
                this.getCart();
            }
          }
                
          this.order_details_loading = false;
        });

    },
    getItemQuantity(item) {
      return parseInt(
        item.item_variants.find(o => o.is_added == 1).quantity
      );
    },
    getAddedCartItems(items){
      return items.filter(
        function(elem) {
          if (elem.is_added == "1") return elem;
        }
      );
    },
    getUnitPrice(item, combo_variants){
      var sum = 0;
      sum += parseFloat(item.discounted_price);
      if(item.choices != null) {
        item.choices.forEach(choice => {
          choice.choice_prices.forEach(choice_price => {
              if(choice_price.is_added == 1){
                sum += parseFloat(choice_price.price);
              }
          });
        });
      }

      if(item.addons != null) {
        item.addons.forEach(addon => {
          addon.addon_prices.forEach(addon_price => {
              if(addon_price.is_added == 1){
                sum += parseFloat(addon_price.price);
              }
          });
        });
      }
      if(combo_variants != null){
        combo_variants.forEach(combo_variant => {

          if(combo_variant.choices != null){
            combo_variant.choices.forEach(choice => {
              choice.choice_prices.forEach(choice_price => {
                if(choice_price.is_added == 1){
                  sum += parseFloat(choice_price.price);
                }
              });
            });
          }

          if(combo_variant.addons != null){
            combo_variant.addons.forEach(addon => {
              addon.addon_prices.forEach(addon_price => {
                if(addon_price.is_added == 1){
                  sum += parseFloat(addon_price.price);
                }
              });
            });
          }

        });
      }
      return parseFloat(sum).toFixed(2);
    },
    calPriceByQty(price, qty){
      var total = (parseFloat(price) * parseFloat(qty))
      return total.toFixed(2);
    },
    getItemUnitPrice(item) {
      return item.item_variants.find(o => o.is_added == 1).discounted_price;
    },
    getDeliverySlot(delivery_slots, selected_slot) {
      return delivery_slots.find(function(value, index) {
        if(selected_slot == index) return value;
      });
    },
    getPickupSlot(pickup_slots, selected_pickup_slot) {
      return pickup_slots.find(function(value, index) {
        if(selected_pickup_slot == index) return value;
      });
    },
    getShopItems(getshopval) {
      if (getshopval != "") {
        this.custitemsplce = [];
        let shop_items = JSON.parse(JSON.stringify(getshopval));
        shop_items.forEach(items => {

          var item_variants = items.item_variants.filter(
            function(elem) {
              if (elem.is_added == "1") return elem;
            }
          );

          item_variants.forEach(item_variant => {
            this.custitemsplce.push({
              item_id: item_variant.item_id,
              item_variants_id: item_variant.item_variants_id,
              quantity: item_variant.quantity,
              price: item_variant.item_price,
              packaging: item_variant.packaging,
              combo_variants: items.combo_variants,
              choices: item_variant.choices,
              addons : item_variant.addons
            });
          });

        });
      }
    },
    getDefaultAddress(user_address){
      return user_address.filter(
        function(elem) {
          if (elem.is_default == "1") return elem;
        }
      );
    },
    getFilteredUserAddress(user){
      var tmp_user_address = [];
      tmp_user_address.push(user.address2, user.address, user.area_name, user.city_name, user.zipcode);
      var filtered_user_address = tmp_user_address.filter(function (el) { return el; });
      
      if(filtered_user_address.length > 0){
        return filtered_user_address.join(', ');
      } else {
        return "";
      }
    },
    getUserAddress() {
      this.profile.user_id = this.getLoginUserId();
      this.address_loading = true;
      this.user_address = '';
      this.user_address_alias = '';
      this.$store.dispatch("customeraddress", this.profile).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        if(result.status == 1){
          var default_address = this.getDefaultAddress(result.data);
          this.user_address = this.getFilteredUserAddress(default_address[0]);
          this.user_address_alias = default_address[0].alias;
        }

        this.getaddressstatus = response.status;
        this.getaddressdetails = response;
                
        this.getbillingaddressdetails = JSON.parse(JSON.stringify(response));
        this.address_loading = false;

        if(this.getaddressdetails.data != undefined && this.getaddressdetails.data.length > 0){
          var is_default= this.getaddressdetails.data.filter(x=>x.is_default==1).length > 0? this.getaddressdetails.data.filter(x=>x.is_default==1)[0] : null;
          if (is_default != null && (this.billing_address_id == '' || this.billing_address_id == undefined))
          {
             this.billing_address_id = is_default.customer_address_id;
             if(is_default != null){
             this.getbillingaddressdetails.data =this.getaddressdetails.data.filter(x=>x.is_default!=1);
             this.getbillingaddressdetails.data = [is_default].concat(this.getbillingaddressdetails.data);
             }
          }
          else
          {
              var billingAddress = this.getbillingaddressdetails.data.filter(x=>x.customer_address_id == this.billing_address_id)[0];
              this.getbillingaddressdetails.data = this.getbillingaddressdetails.data.filter(x=>x.customer_address_id != this.billing_address_id)
              if(billingAddress != null){
                this.getbillingaddressdetails.data = [billingAddress].concat(this.getbillingaddressdetails.data)
              }
          }
          if(is_default != null){
            this.getaddressdetails.data =this.getaddressdetails.data.filter(x=>x.is_default!=1);
          this.getaddressdetails.data = [is_default].concat(this.getaddressdetails.data);
          }
        }
        return this.getaddressstatus;
      });
    },
    getShopOffrs(getmainstoreid) {
      this.custshopcatgryoffrs.user_id = this.getLoginUserId();
      this.custshopcatgryoffrs.store_id = getmainstoreid;
      this.custshopcatgryoffrs.is_store_listing = 0;
      this.$store
        .dispatch("getshopcatgryoffrs", this.custshopcatgryoffrs)
        .then(response => {
          this.getOfferlistdata = JSON.parse(JSON.stringify(response));
        });
    },
    setBillingAddress(custadrid) {
      this.billing_address_id = custadrid;
      localStorage.setItem("billing_address_id",this.billing_address_id)
      this.getUserAddress();
    },
    changeBillingAddress()
    {
      this.popup_address_type = 0;
      var billingAddress = this.getaddressdetails.data.filter(x=>x.customer_address_id == this.billing_address_id)[0];
      this.getaddressdetails.data = this.getaddressdetails.data.filter(x=>x.customer_address_id != this.billing_address_id)
      if(billingAddress != null)
      {
        this.getaddressdetails.data = [billingAddress].concat(this.getaddressdetails.data)
      }
      this.change_address_mode = "Billed to";
      localStorage.setItem("address_change_type","billingaddress");
      window.$('#ManageAddressModal').modal('show');
    },
    changeDeliveryAddress()
    {
      this.popup_address_type = 1;
      var is_default= this.getaddressdetails.data.filter(x=>x.is_default==1).length > 0? this.getaddressdetails.data.filter(x=>x.is_default==1)[0] : null;
      if (is_default != null)
      {
        if(is_default != null)
        {
          this.getaddressdetails.data =this.getaddressdetails.data.filter(x=>x.is_default!=1);
          this.getaddressdetails.data = [is_default].concat(this.getaddressdetails.data);
        }
      }
      this.change_address_mode = "Deliver to";
      window.$('#ManageAddressModal').modal('show');      
    },
    setDeliveryAddress(custadrid, addrsobj) {
      this.address_loading = true;
      if(localStorage.getItem("address_change_type") != null && localStorage.getItem("address_change_type") != undefined && localStorage.getItem("address_change_type") == "billingaddress")
      {
        localStorage.removeItem("address_change_type")
        this.billing_address_id = custadrid;
        localStorage.setItem("billing_address_id",this.billing_address_id)
        this.getUserAddress();
        var billingAddress = this.getbillingaddressdetails.data.filter(x=>x.customer_address_id == this.billing_address_id)[0];
        this.getbillingaddressdetails.data = this.getbillingaddressdetails.data.filter(x=>x.customer_address_id != this.billing_address_id)
        if(billingAddress != null)
        {
          this.getbillingaddressdetails.data = [billingAddress].concat(this.getbillingaddressdetails.data)
        }        
        this.address_loading = false;
        window.$("#ManageAddressModal").modal("hide");
        this.change_address_mode = "Deliver to";
        return;
      }
      this.customeraddres.user_id = this.getLoginUserId();
      this.customeraddres.customer_address_id = custadrid;
      this.customeraddres.is_default = "1";
      this.customeraddres.alias = addrsobj.alias;
      this.customeraddres.alias_code = addrsobj.alias_code;
      this.customeraddres.address1 = addrsobj.address;
      this.customeraddres.address2 = addrsobj.address2;
      this.customeraddres.zipcode1 = addrsobj.zipcode1;
      this.customeraddres.country = addrsobj.country_name1;
      this.customeraddres.state = addrsobj.state_name1;
      this.customeraddres.city = addrsobj.city_name1;
      this.customeraddres.latitude = addrsobj.lat;
      this.customeraddres.longitude = addrsobj.long;
      
      localStorage.setItem("billing_address_id",this.billing_address_id)
      this.$store
        .dispatch("managecustomeraddrs", this.customeraddres)
        .then(() => {
          this.getUserAddress();

          this.location_details.customer_address_id = custadrid;
          this.location_details.lat = addrsobj.lat;
          this.location_details.lng = addrsobj.long;
          
          var area_name = addrsobj.area_name + ', ';
          var city_name = addrsobj.city_name + ' ' + addrsobj.zipcode +', ';
          var state_name = (addrsobj.state_name != '') ? addrsobj.state_name + ', ' : '';
          var country = (addrsobj.country_name != '') ? addrsobj.country_name : '';
          this.location_details.address = area_name + city_name + state_name + country;
          localStorage.setItem("location_details", JSON.stringify(this.location_details));

          // this.getDeliveryCharges();
          this.getCart();
          this.address_loading = false;
          window.$("#ManageAddressModal").modal("hide");
          
        });
    },
    getFilteredStoreAddress(store){
      var tmp_store_address = [];
      tmp_store_address.push(store.store_address, store.store_address2, store.area_name, store.city_name, store.zipcode);
      var filtered_store_address = tmp_store_address.filter(function (el) { return el; });
      
      if(filtered_store_address.length > 0){
        return filtered_store_address.join(', ');
      } else {
        return "";
      }
    },
    getCart() {
      this.loading=true;
      this.custprodctdetail.user_id = this.getLoginUserId();
      this.custprodctdetail.cart_type = 1;
      this.custprodctdetail.timezone = "+6";
      this.custprodctdetail.offer_id = this.offer_id;
      this.custprodctdetail.offer_discount = this.offer_discount;
      this.custprodctdetail.item_total = this.item_total;
      this.$store
        .dispatch("getcartdetail", this.custprodctdetail)
        .then(response => {
          this.getcartdata = JSON.parse(JSON.stringify(response));
          if(this.getcartdata.data!=null){
            var availablePaymentMethods = this.getcartdata.data.store_payments.filter(function(item) {
            return item.payment_method_id !== 3 && item.is_added == 1
            });

            if (availablePaymentMethods.length != undefined && availablePaymentMethods.length == 1)
            {
              this.payment_method = availablePaymentMethods[0].payment_method_id;
            }

            this.getcartdetail = JSON.parse(JSON.stringify(response.data));
            this.zone_days = this.getcartdetail.zone_days;
            
            this.getShopItems(this.getcartdetail.items);
            this.getShopOffrs(this.getcartdata.data.store.store_id);
            this.store_address = this.getFilteredStoreAddress(this.getcartdata.data.store);

            if (this.use_google_map == 1)
              this.store_map_link = 'https://www.google.com/maps/search/?api=1&query='+this.getcartdata.data.store.latitude+','+this.getcartdata.data.store.longitude;
            else
              this.store_map_link = 'https://www.google.com/maps/search/?api=1&query='+this.store_address;

            if(this.delivery_pickup == 1){
              this.getDeliveryCharges();
            }
            if(this.$route.query.message != undefined){
              this.$router.push('/paymentinfo?errmsg='+this.$route.query.message);
            }
            else if(this.$route.query.result != undefined)
            {
              this.placeOrder('direct')
            }
            this.loading = false;
            this.order_details_loading = false;
            this.iseatry = this.getcartdata.data.store.iseatery;
          }
        });
    },
    zoneFormat(){
      var temp;
        this.zone_days.forEach((element, index) => {
          if(!temp){
            temp = element
          }else{
            if(this.zone_days.length -1 == index){
              temp = temp +' & '+element
            }else{
              temp = temp +', '+element
            }
          }
        });
        return temp;
    },
    checkMinOrderAmount(){
      if(this.offer_discount){
        return parseFloat(parseFloat(this.itemTotal) - parseFloat(this.offer_discount)) < parseFloat(this.getcartdata.data.order_settings.min_order_amount);
      }else{
        return parseFloat(this.itemTotal) < parseFloat(this.getcartdata.data.order_settings.min_order_amount);
      }
    },
    
    async verifyOrder(){
      this.btnPlaceOrder = true;

      if (this.show_billing_address == 1)
      {
        var billingAddressObject = this.getbillingaddressdetails.data.filter(x=>x.customer_address_id == this.billing_address_id);
        if(billingAddressObject[0].address2 == "")
        {
            this.$toast.error(validationmessage, {timeout:15000});
            this.btnPlaceOrder = false;            
            return false;
        }
      }
      if(this.delivery_pickup == 1){
        if(this.delivery_now_later == 1 && this.getcartdata.data.order_settings.delivery_slots != null && this.getcartdata.data.order_settings.delivery_time_type == 1){
          if(this.selected_slot == '' && this.selected_slot != '0'){
            this.$toast.error("Please select the Delivery Slot");
            this.btnPlaceOrder = false;            
            return false;
          }
        }

        if(this.delivery_now_later == 2){
          if(this.scheduled_date == '' || this.scheduled_time == ''){
            this.$toast.error("Please enter the Scheduled Delivery Time");
            this.btnPlaceOrder = false;            
            return false;
          }
        }

      }

      if(this.itemTotal >= parseFloat(this.amount_for_id_proof)){
        if (!this.id_proof){
            this.$toast.error("Please select the Id Proof");
            this.btnPlaceOrder = false;            
            return false;
        }
      }
      
      if(this.itemTotal >= parseFloat(this.amount_for_signature)){
        if (!this.$refs.signaturePad.saveSignature().data){
            this.$toast.error("Please select the Signature");
            this.btnPlaceOrder = false;            
            return false;
        }
      }
      

      if(this.delivery_pickup == 2){
        if(this.delivery_now_later == 1 && this.getcartdata.data.order_settings.pickup_slots != null && this.getcartdata.data.order_settings.pickup_time_type == 1){
          if(this.selected_pickup_slot == '' && this.selected_pickup_slot != '0' && this.iseatry == 1){
            this.$toast.error("Please select the Take Away Slot");
            this.btnPlaceOrder = false;
            return false;
          }
          if(this.selected_pickup_slot == '' && this.selected_pickup_slot != '0' && this.iseatry != 1){
            this.$toast.error("Please select the Pickup Slot");
            this.btnPlaceOrder = false;            
            return false;
          }
        }

        if(this.delivery_now_later == 2){
          if(this.scheduled_pickup_date == '' || this.scheduled_pickup_time == ''){
            this.$toast.error("Please enter the Scheduled Pickup Time");
            this.btnPlaceOrder = false;            
            return false;
          }
        }
      }
      

      let finalAmount = (this.grandTotal-(Number(this.grandTotal) > Number(this.getcartdata.data.wallet_balance)?(this.getcartdata.data.wallet_balance):(Number(this.grandTotal) > Number(this.wallet_max_amount) ? this.wallet_max_amount : this.grandTotal)));
      if(!(finalAmount <=0 && this.is_wallet_applied))
      {

      if(this.paymentMethods.length != 1){
        if((this.payment_method == '' && this.payment_method != '0')){
        this.$toast.error("Please select the Payment Method");
        this.btnPlaceOrder = false;                    
        return false;
      }
      }
      }

      if(this.delivery_pickup == 1 && this.getcartdata.data.order_settings.delivery_charge_type == 1 && this.getcartdata.data.order_settings.min_order_amount > 0 && this.checkMinOrderAmount()){
        this.$toast.error("Order value should be above Minimum Order value ("+this.currency_symbol + parseFloat(this.getcartdata.data.order_settings.min_order_amount)+")");
        this.btnPlaceOrder = false;            
        return false;
      }

      if(this.delivery_charge_msg != "" && this.delivery_pickup == 1){
        this.$toast.error(this.delivery_charge_msg);
        this.btnPlaceOrder = false;            
        return false;
      }
      
      if(this.delivery_now_later == 2 && this.delivery_pickup == 1){
        let _this = this;
        await this.getStoreBusinessDays().then((result)=>{_this.is_open = result});
        if(this.is_open == false){
          this.$toast.error('Select Delivery Date-Time From Business Hours');
          this.btnPlaceOrder = false;            
          return false;    
        }
        
        let scheduled_delivery_date = (this.scheduled_date + " " + this.scheduled_time);      
        if(scheduled_delivery_date < this.getcartdata.data.schedule_delivery_date){
          this.$toast.error('Delivery Date-Time should not be before '+this.getcartdata.data.schedule_delivery_date);
          this.btnPlaceOrder = false;            
          return false;
        }
      }

      if(this.delivery_now_later == 2 && this.delivery_pickup == 2){       
        let scheduled_pickup_date = (this.scheduled_pickup_date + " " + this.scheduled_pickup_time);
        let _this = this;
        await this.getStoreBusinessDays().then((result)=>{_this.is_open = result});
        if(this.is_open == false){
          this.$toast.error('Select Delivery Date-Time From Business Hours');
          this.btnPlaceOrder = false;            
          return false;    
        }
      
        if(scheduled_pickup_date < this.getcartdata.data.schedule_pickup_date){
          this.$toast.error('Delivery Date-Time should not be before '+this.getcartdata.data.schedule_pickup_date);
          this.btnPlaceOrder = false;            
          return false;
        }     
      }
      
      if(this.getcartdata.data.order_settings.delivery != 1 && this.getcartdata.data.order_settings.pickup != 1)
      {
         this.$toast.error("This store does not accept order outside of business hours",{timeout:5000});
         this.btnPlaceOrder = false;                     
         return false;
      }
      if(this.getcartdata.data.items.filter(x=>x.prescription == 1).length > 0)
      {
        if(this.custordrdelvryplace.order_prescription_images.length == 0)
        {
          this.$toast.error('Please upload prescription image');
          this.btnPlaceOrder = false;                      
          return false;
        }
      }
      
      if(this.paymentMethods.length == 1){
        this.selectedpaymentmethodname = 
        (this.getcartdata.data.store_payments.length > 0 && this.paymentMethods != null  && this.paymentMethods != "") ? 
        this.paymentMethods[0].method_name_display: '';
        this.selectedpaymentmethodname = (this.grandTotal-(Number(this.grandTotal) > Number(this.getcartdata.data.wallet_balance)?(this.getcartdata.data.wallet_balance):(Number(this.grandTotal) > Number(this.wallet_max_amount) ? this.wallet_max_amount : this.grandTotal))) <= 0 && this.is_wallet_applied == true ? '':this.selectedpaymentmethodname;
        this.selectedpaymentmethodname = (this.is_wallet_applied  == true) ? this.selectedpaymentmethodname = this.selectedpaymentmethodname + (this.selectedpaymentmethodname != "" ? " , ":"") + "Wallet" : this.selectedpaymentmethodname;  
      } else {
        this.selectedpaymentmethodname = 
        (this.getcartdata.data.store_payments.length > 0 && this.payment_method != null  && this.payment_method != "") ? 
        this.getcartdata.data.store_payments.filter(x=>x.payment_method_id == this.payment_method)[0].method_name_display: '';
        this.selectedpaymentmethodname = (this.grandTotal-(Number(this.grandTotal) > Number(this.getcartdata.data.wallet_balance)?(this.getcartdata.data.wallet_balance):(Number(this.grandTotal) > Number(this.wallet_max_amount) ? this.wallet_max_amount : this.grandTotal))) <= 0 && this.is_wallet_applied == true ? '':this.selectedpaymentmethodname;
        this.selectedpaymentmethodname = (this.is_wallet_applied  == true) ? this.selectedpaymentmethodname = this.selectedpaymentmethodname + (this.selectedpaymentmethodname != "" ? " , ":"") + "Wallet" : this.selectedpaymentmethodname;
      }
      if(this.payment_method == 4){
        this.$validator.validateAll('form2').then(result => {
          if (result) {
            if(this.payment_method == 4 && this.add_newcard == false && this.customer_card_id == 0){
              this.$toast.error('Please select a card');
              return false;
            } else {
              window.$('#OrderConfirmationModal').modal("show");  
            }    
          } else {
            this.$toast.error('Please enter the credit card details');
            return false;
          }
        });
      } else {
        window.$('#OrderConfirmationModal').modal("show");  
      }
      this.btnPlaceOrder = false;  

    },
    async getStoreBusinessDays(){
      this.store_business_days_data.store_id = this.getcartdata.data.store.store_id;     
      var isOpen = false;
      return await this.$store
        .dispatch("getstorebusinesshours", this.store_business_days_data)
        .then(response => {
          if(response.status == 1){
            this.store_business_days = response.data;
            var selected_day = 0;
            if(localStorage.getItem("delivery_pickup") == 1){
              selected_day = this.getDayfromDate(this.scheduled_date);
            } else{
              selected_day = this.getDayfromDate(this.scheduled_pickup_date);
            }
            this.store_business_days.business_days.forEach(data => {
              if(data.day == selected_day && data.is_open == 1){               
                var openTime = data.from_time.split(":");
                var closeTime = data.to_time.split(":");               
                var storeOpenHour = false;
                var storeCloseHour = false;
                if(localStorage.getItem("delivery_pickup") == 1){
                  if(parseInt(openTime[0]) <= this.scheduled_time.split(":")[0]){
                    if(parseInt(openTime[0]) == parseInt(this.scheduled_time.split(":")[0])){
                      if(parseInt(openTime[1]) <= this.scheduled_time.split(":")[1]){
                        storeOpenHour = true;
                      } else {
                        storeOpenHour = false;                        
                      }
                    } else {
                      storeOpenHour = true;
                    }
                  }

                  if (parseInt(closeTime[0]) >= parseInt(this.scheduled_time.split(":")[0])) {
                    if (parseInt(closeTime[0]) == parseInt(this.scheduled_time.split(":")[0])) {
                      if (parseInt(closeTime[1]) >= parseInt(this.scheduled_time.split(":")[1])) {
                        storeCloseHour = true;
                      } else {
                        storeCloseHour = false;
                      }
                    } else {
                      storeCloseHour = true;
                    }
                  }
                } else {
                  if(parseInt(openTime[0]) <= this.scheduled_pickup_time.split(":")[0]){
                    if(parseInt(openTime[0]) == parseInt(this.scheduled_pickup_time.split(":")[0])){
                      if(parseInt(openTime[1]) <= this.scheduled_pickup_time.split(":")[1]){
                        storeOpenHour = true;                        
                      } else {
                        storeOpenHour = false;
                      }
                    } else {
                      storeOpenHour = true;
                    }
                  }

                  if (parseInt(closeTime[0]) >= parseInt(this.scheduled_pickup_time.split(":")[0])) {
                    if (parseInt(closeTime[0]) == parseInt(this.scheduled_pickup_time.split(":")[0])) {
                      if (parseInt(closeTime[1]) >= parseInt(this.scheduled_pickup_time.split(":")[1])) {
                        storeCloseHour = true;                        
                      } else {
                        storeCloseHour = false;
                      }
                    } else {
                      storeCloseHour = true;
                    }
                  }
                }

                if (storeOpenHour && storeCloseHour) {
                  this.is_open = true;
                  isOpen = this.is_open;
                }
              }
            });
            }
            return isOpen;
        });   
    },
    getDayfromDate(current_date){
      var dateFormat = localStorage.getItem("date_format");
      var date;
      if(dateFormat == 'DD-MM-YYYY HH:mm:ss'){
        date = moment(current_date, 'DD-MM-YYYY');
      } else {
        date = moment(current_date, 'MM-DD-YYYY');
      }
      var day_name = date.format('dddd');
      return day_name;
    },
    isStoreOpen(business_hours){
      if (business_hours != null && business_hours.length > 0) {
          var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          var openTime = business_hours[0].from_time;
          var closeTime = business_hours[0].to_time;
          
          const now = moment.tz(timezone);
          const date = now.format("YYYY-MM-DD");
          var current_day = now.day();
          var new_current_day;

          switch (current_day) {
            case 0:
            new_current_day = 6;
            break;
            case 1:
            new_current_day = 0;
            break;
            case 2:
            new_current_day = 1;
            break;
            case 3:
            new_current_day = 2;
            break;
            case 4:
            new_current_day = 3;
            break;
            case 5:
            new_current_day = 4;
            break;
            case 6:
            new_current_day = 5;
          }

          if(new_current_day != business_hours[0].days){
            return false;
          }

          let check;
          const storeOpenTime = moment.tz(date + ' ' + openTime, "YYYY-MM-DD h:mmA", timezone);
          const storeCloseTime = moment.tz(date + ' ' + closeTime, "YYYY-MM-DD h:mmA", timezone);

          if (storeCloseTime.isBefore(storeOpenTime)) {
            check = now.isAfter(storeOpenTime) || now.isBefore(storeCloseTime);
          } else {
            check = now.isBetween(storeOpenTime, storeCloseTime, null, '[)');
          }
          return check ? true : false;
      } else {
        return false;
      }
    },
    openAddAddressPopup(){
      if (this.use_google_map == 1)
      {
        this.getLocation();
      }
      window.$('#address-pop-up').modal('show')
    },
    placeOrder(mode) {
      
      this.btnPlaceOrder = true;
      this.loading=true;
      var dateFormat = localStorage.getItem("date_format");
      var date = new Date();
      let finalAmount = (this.grandTotal-(Number(this.grandTotal) > Number(this.getcartdata.data.wallet_balance)?(this.getcartdata.data.wallet_balance):(Number(this.grandTotal) > Number(this.wallet_max_amount) ? this.wallet_max_amount : this.grandTotal)));
      this.custordrdelvryplace.user_id = this.getLoginUserId();
      this.custordrdelvryplace.store_id = this.getcartdata.data.store.store_id;
      localStorage.setItem("order_storeid",this.custordrdelvryplace.store_id);
      this.custordrdelvryplace.date = dateFormat == "DD-MM-YYYY HH:mm:ss" ? moment(date).format('DD-MM-YYYY') : moment(date).format('MM-DD-YYYY');
      this.custordrdelvryplace.items = JSON.stringify(this.custitemsplce);
      this.custordrdelvryplace.amount = this.grandTotal;
      this.custordrdelvryplace.remarks = this.remarks;
      this.custordrdelvryplace.order_type = this.delivery_pickup;
      this.custordrdelvryplace.payment_method_id = this.payment_method.toString();
      this.custordrdelvryplace.is_scheduled = this.delivery_now_later == "2" ? "1" : "0";
      this.custordrdelvryplace.packaging_cost = this.getcartdata.data.packaging_cost;
      this.custordrdelvryplace.order_tax = this.getcartdata.data.total_tax;
      this.custordrdelvryplace.pay_thru_wallet = 0;
      this.custordrdelvryplace.wallet_amount = 0;
      this.custordrdelvryplace.id_proof = this.id_proof;
      this.custordrdelvryplace.delivery_slot =  JSON.stringify(this.getcartdata.data.order_settings.delivery_slots[this.selected_slot]);

      if(this.$refs.signaturePad!=undefined && this.$refs.signaturePad.saveSignature().data)
      {
        var dataurl = this.$refs.signaturePad.saveSignature().data;
        var arr = dataurl.split(','),
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        this.signature = new File([u8arr], 'signature.png', { type: "image/png" });
        this.custordrdelvryplace.signature = this.signature;
      }

      this.custordrdelvryplace.card_name = this.ccname;
      this.custordrdelvryplace.card_number = this.ccnumber;
      this.custordrdelvryplace.expiration_date = this.ccexpiration;
      this.custordrdelvryplace.card_code = this.cccvv;
      this.custordrdelvryplace.item_total = this.itemTotal;

      if(this.save_cards_status == 1 && this.add_newcard == false){
        this.custordrdelvryplace.customer_profile_id = this.customer_profile_id;
        this.custordrdelvryplace.payment_profile_id = this.payment_profile_id;  
      }      

      if (this.show_billing_address == 1)
      {
        var billingAddressObject = this.getbillingaddressdetails.data.filter(x=>x.customer_address_id == this.billing_address_id);
        if(billingAddressObject[0].address2 == "")
        {
            this.$toast.error("Your selected Delivery Address is incomplete.Please edit it in Address Book before proceeding further.");
        }
        this.custordrdelvryplace.billing_address_id = this.billing_address_id;
      }
      if(this.is_wallet_applied == true)
      {
        this.custordrdelvryplace.pay_thru_wallet = 1;
        this.custordrdelvryplace.wallet_amount = (Number(this.grandTotal) > Number(this.getcartdata.data.wallet_balance)?(Number(this.getcartdata.data.wallet_balance) > Number(this.wallet_max_amount) ? this.wallet_max_amount : this.getcartdata.data.wallet_balance):(Number(this.grandTotal) > Number(this.wallet_max_amount) ? this.wallet_max_amount : this.grandTotal));
      }
      if(this.delivery_pickup == 1){
        if(this.delivery_now_later == 1 && this.getcartdata.data.order_settings.delivery_slots != null && this.getcartdata.data.order_settings.delivery_time_type == 1){
          var delivery_slots = this.getcartdata.data.order_settings.delivery_slots;
          let get_delivery_slot = this.getDeliverySlot(delivery_slots, this.selected_slot);
          if(get_delivery_slot != null){
            this.custordrdelvryplace.delivery_slot_from = get_delivery_slot.from_time;
            this.custordrdelvryplace.delivery_slot_to = get_delivery_slot.to_time;
          }
        }
        
        if(this.delivery_now_later == 2 ){
            let scheduled_delivery_date = (this.scheduled_date + " " + this.scheduled_time+":00");
            this.custordrdelvryplace.scheduled_delivery_date = scheduled_delivery_date;
        }
      }

      if(this.delivery_pickup == 2){
        if(this.delivery_now_later == 1 && this.getcartdata.data.order_settings.pickup_slots != null && this.getcartdata.data.order_settings.pickup_time_type == 1){
          var pickup_slots = this.getcartdata.data.order_settings.pickup_slots;
          let get_pickup_slot = this.getPickupSlot(pickup_slots, this.selected_pickup_slot);
          if(get_pickup_slot != null){
            this.custordrdelvryplace.pickup_slot_from = get_pickup_slot.from_time;
            this.custordrdelvryplace.pickup_slot_to = get_pickup_slot.to_time;
          }
        }
        if(this.delivery_now_later == 2){
            let scheduled_pickup_date = (this.scheduled_pickup_date + " " + this.scheduled_pickup_time+":00");
            this.custordrdelvryplace.scheduled_delivery_date = scheduled_pickup_date;
        }
      }

      let delivery_charge = '0';
      if(this.delivery_charges != null && this.delivery_charges.status != "0"){
        delivery_charge = this.delivery_charges.data.delivery_charges;
      }
      this.custordrdelvryplace.delivery_charges = delivery_charge;

      if(this.appliedoffrcode != '')
        this.custordrdelvryplace.offer_code = this.appliedoffrcode;

      if(this.offer_discount != '0')
        this.custordrdelvryplace.offer_discount = this.offer_discount;
      this.payment_method = (finalAmount <= 0 && this.is_wallet_applied == true) ? "" : this.payment_method;
      if (this.selectedpaymentmethodname == "Wallet")
      {
        this.payment_method = "";
        this.custordrdelvryplace.payment_method_id = "";
      }
      
      if(this.payment_method != 5 && this.payment_method != 8 && mode == 'click') 
      {
        this.$store
        .dispatch("getdelvrycustorderplaced", this.custordrdelvryplace)
        .then(response => {
          window.$('#OrderConfirmationModal').modal("hide");
          if (response.status == 0) {
            this.loading=false;
            this.$toast.error(response.message);
            this.btnPlaceOrder = false; 
            this.$router.push({ name: "cart" });
          } else {
            this.$toast.success(response.message);
            this.createcustomerchat.order_id = response.data.order_id;
            this.createcustomerchat.user_id = response.data.user_id;
            this.$store
            .dispatch("createcustomerchat", this.createcustomerchat)
            .then(() => {
              this.loading=false;
              localStorage.setItem("orderplaced_id", response.data.order_id);
              this.$router.push({ name: "orderplaced" });
              this.btnPlaceOrder = false;  
            });
          }
        });
      } 
      else if(mode == 'direct') {
          if(localStorage.getItem("order_details_online_payment") != null && localStorage.getItem("order_details_online_payment") != undefined)
          {
            this.custordrdelvryplace = JSON.parse(localStorage.getItem("order_details_online_payment"));
          }
          this.custordrdelvryplace.payment_method_id = localStorage.getItem("paymentmethodid");
          this.custordrdelvryplace.call_back_url=window.location.href;
          this.custordrdelvryplace.transactionid= localStorage.getItem("paymenttransactionid");
          this.$store
          .dispatch("getdelvrycustorderplaced", this.custordrdelvryplace)
          .then(response => {
            window.$('#OrderConfirmationModal').modal("hide");
            if (response.status == 0) {
              this.$toast.error(response.message);
              localStorage.removeItem("paymentmethodid");
              localStorage.removeItem("paymenttransactionid");
              localStorage.removeItem("order_details_online_payment")
              setTimeout(() => (window.location.reload()), 3000);
              this.btnPlaceOrder = false;              
              this.loading=false;
            } else {
              this.$toast.success(response.message);
              
              this.createcustomerchat.order_id = response.data.order_id;
              this.createcustomerchat.user_id = response.data.user_id;
              this.$store
              .dispatch("createcustomerchat", this.createcustomerchat)
              .then(() => {
                this.loading=false;
                localStorage.removeItem("paymentmethodid");
                localStorage.removeItem("paymenttransactionid");
                localStorage.removeItem("order_details_online_payment")              
                localStorage.setItem("orderplaced_id", response.data.order_id);
                this.$router.push({ name: "orderplaced" });
                this.btnPlaceOrder = false;  
              });
            }
          });
      } 
      else if(this.payment_method == 5 || this.payment_method == 8) {
          if(this.is_wallet_applied == true)
          {
            localStorage.setItem("Is_wallet_Applied","walletapplied")
          }
          localStorage.setItem("order_details_online_payment",JSON.stringify(this.custordrdelvryplace))
          this.custordrdelvryplace.call_back_url=window.location.href;
          this.$store
          .dispatch("generatepaytransaction", this.custordrdelvryplace)
          .then(response => {
            window.$('#OrderConfirmationModal').modal("hide");
            if (response.status == 1) {
              localStorage.setItem("paymenttransactionid",response.data.transaction_id);
              localStorage.setItem("paymentmethodid",this.payment_method);
              window.location.replace(response.data.transaction_url);
            }
            this.btnPlaceOrder = false;  
            this.loading=false;
            return false;
          });
          this.btnPlaceOrder = false;
          return false;
      }
    },
    removeCart() {
      this.custcartremve.user_id = this.getLoginUserId();
      this.custcartremve.cart_type = 1;
      this.$store
        .dispatch("getemptycart", this.custcartremve)
        .then(response => {
          this.$toast.success(JSON.parse(JSON.stringify(response)).message);
          this.$router.push({ name: "home" });
        });
    },
    getStoreByCategory(category){
      if(this.is_business_model == 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
    getLoginUserId(){
      return (this.userprofile != null) ? this.userprofile.user_id : "0";
    },
    getDeliveryCharges() {
      this.getaddressdetails.data.forEach(x=>{
        if(x.is_default == 1){
          this.check_lat = x.lat;
          this.check_long = x.long
        }
      })
      this.delivery_charge_msg = "";
      this.delivery_charges = null;
      this.order_details_loading = true;
      this.delivery_charges_body.user_id = this.getLoginUserId();
      this.delivery_charges_body.store_id = this.getcartdata.data.store.store_id;
      
      var order_amount = parseFloat(this.itemTotal);
      this.delivery_charges_body.order_amount = order_amount;

      if(JSON.parse(localStorage.getItem("location_details")).customer_address_id != undefined) {
        this.delivery_charges_body.lat = this.check_lat ? this.check_lat :JSON.parse(localStorage.getItem("location_details")).lat;
        this.delivery_charges_body.long = this.check_long ? this.check_long : JSON.parse(localStorage.getItem("location_details")).lng;
      } else {
        this.delivery_charges_body.lat = "";
        this.delivery_charges_body.long = "";
      }

      this.$store
        .dispatch("getcustdeliverycharges", this.delivery_charges_body)
        .then(response => {
          var result  = JSON.parse(JSON.stringify(response));
          if (result.status != "0") {
            this.delivery_charges = JSON.parse(JSON.stringify(result));
          } else {
            this.delivery_charge_msg = result.message;
            this.$toast.error(this.delivery_charge_msg);
          }
          this.order_details_loading = false;
        });
    },
    getSaveCard(){
      this.save_cards.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.save_cards.page = 1;
      this.$store.dispatch("savecards", this.save_cards).then(response => {
        this.save_cards_status = response.status;
        this.save_cards_data = response.data;
        if(this.save_cards_status ==  1 ){
          this.add_newcard = false;
        } else {
          this.add_newcard = true;
        }
      });
    },
    changeCard(e){
      var card_object = this.save_cards_data.filter(function(data){
        if(e.target.value == data.customer_card_id) return data;
      });
      if(card_object[0]!=undefined){
        this.customer_profile_id = card_object[0].customer_profile_id;
        this.payment_profile_id = card_object[0].payment_profile_id;
      } else {
        this.customer_profile_id = "";
        this.payment_profile_id = "";
      }
    },
    addNewCard(){
      this.customer_card_id = 0;
      this.customer_profile_id = "";
      this.payment_profile_id = "";
    },
    yesClicked(){
      this.isDeletePopupVisible = true;
    },
    noClicked(){
      this.isDeletePopupVisible = false;
    },
    deleteCard(card_id){
      this.delete_card.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.delete_card.customer_card_id = card_id;
      // if(this.save_cards_data.length == 1){
      //   this.$toast.error("Last Card Details can not be deleted.");
      //   return false;
      // }
      if(this.save_cards_data.length >= 1){
        this.$store.dispatch("deletecards", this.delete_card).then(response => {
          if(response.status == 1 && this.save_cards_data.length >= 1){
            this.$toast.error(response.message);
            this.getSaveCard();
            this.customer_card_id = 0;
          }

          if(response.status == 0){
            this.$toast.error(response.message);
            return false;
          }
        });  
      }
      this.isDeletePopupVisible = false;
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    openIdProof(){
       this.$refs.IdProofRef.click();
    },
    // selectIdProof(event) {
    //     this.id_proof = event.target.files[0];
    //     this.selectedIdProof = this.id_proof.name;
    //     const reader = new FileReader();
    //     reader.readAsDataURL(this.id_proof);
    //     reader.onload = (event) => {
    //     this.imagePreview = event.target.result;
    //   };
    // },
    selectIdProof() {
      this.id_proof = this.$refs.IdProofRef.files[0];
      if (this.id_proof) {
        if (/\.(jpg|jpeg|png)$/i.test(this.id_proof.name)) {
          this.selectedIdProof = this.id_proof.name;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(this.id_proof);
        } else if (/\.pdf$/i.test(this.id_proof.name)) {
          this.selectedIdProof = this.id_proof.name;
          this.imagePreview = null;
        } else {
          this.selectedIdProof = null;
          this.imagePreview = null;
        }
      }
    },

    removeIdProof() {
      this.selectedIdProof = '';
      this.imagePreview = null;
      this.$refs.IdProofRef.value = '';
      this.id_proof = '';
    },
  }
};
</script>
<style>
 .delete-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); */
  width: 420px;

}
</style>